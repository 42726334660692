import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { createAuthStore } from './Auth'
import { IAuth } from './Auth/interface'
import { createMessageStore, IMessageStore } from './Message'
import {
  createUserProfileStore,
  IProfileUserStore,
} from './UserProfile'
import { ICategoryStore } from './Category/interface'
import { createCategoryStore } from './Category'
import { IReferralStore } from './Referral/interface'
import { createReferralStore } from './Referral'

export const useAuthStore = create<IAuth>()((...a) => ({
  ...createAuthStore(...a),
}))

export const useMessageStore = create<IMessageStore>()(
  devtools(
    persist((...a) => ({
      ...createMessageStore(...a),
    })),
    {
      name: 'message-store',
    },
  ),
)

export const useProfileUserStore = create<IProfileUserStore>()(
  devtools(
    (...a) => ({
      ...createUserProfileStore(...a),
    }),
    {
      name: 'user-profile-store',
    },
  ),
)

export const useCategoryStore = create<ICategoryStore>()(
  persist(
    (...a) => ({
      ...createCategoryStore(...a),
    }),
    {
      name: 'category-store',
      partialize: (state) => ({ categories: state.categories }),
    },
  ),
)

export const useReferralStore = create<IReferralStore>()(
  persist(
    (...a) => ({
      ...createReferralStore(...a),
    }),
    {
      name: 'referral-store',
      partialize: (state) => ({
        referralCodeInfo: state.referralCodeInfo,
      }),
    },
  ),
)
