import 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import firebaseConfig from 'configs/firebase'
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'
import { getAnalytics } from 'firebase/analytics'
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import { IActionUserDevice } from 'constants/user'
import { sendLog } from 'helpers/log'
import { updateUserDevice } from 'services/user'
import isEmpty from 'helpers/is-empty'
import { isGuestMode } from 'helpers/cookie'

const firebaseCloudMessaging = {
  init: async () => {
    // Initialize the Firebase app with the credentials
    const app = initializeApp(firebaseConfig)
    getAnalytics(app)

    try {
      const isSupportedBrowser = await isSupported()
      if (isSupportedBrowser) {
        const messaging = getMessaging()
        const tokenInLocalForage = await getLocalStorage('fcmToken')

        // // Return the token if it is alredy in our local storage
        if (!isEmpty(tokenInLocalForage?.token)) {
          return tokenInLocalForage.token
        }

        // Request the push notification permission from browser
        const status = await Notification.requestPermission()
        if (status && status === 'granted') {
          // Get new token from Firebase
          const fcm_token = await getToken(messaging, {
            vapidKey: firebaseConfig.vapidKey,
          })
          if (fcm_token) {
            setLocalStorage('fcmToken', { token: fcm_token })
            return fcm_token
          }
        }
      }
    } catch (error) {
      sendLog(
        `Error init firebase: ${error?.code} - ${error?.message}`,
      )
      return null
    }
  },
}

const setFcmToken = async (action: IActionUserDevice) => {
  try {
    if (!isGuestMode()) {
      const token = await firebaseCloudMessaging.init()
      if (token) {
        await updateUserDevice(token, action)
      }
    }
    return Promise.resolve()
  } catch (error) {
    sendLog(`Error set fcm token: ${error?.code} - ${error?.message}`)
  }
}

const onMessageListener = async () =>
  new Promise((resolve) => async () => {
    initializeApp(firebaseConfig)
    isSupported()
      .then(() => {
        const messaging = getMessaging()
        onMessage(messaging, (payload) => {
          resolve(payload)
        })
      })
      .catch((error) =>
        sendLog(
          `Error not supported firebase: ${error?.code} - ${error?.message}`,
        ),
      )
  }).catch((error) =>
    sendLog(
      `Error message listener: ${error?.code} - ${error?.message}`,
    ),
  )

export { firebaseCloudMessaging, onMessageListener, setFcmToken }
