import { Radio, useTheme } from '@mui/material'
import ActionButton from 'components/commons/Button/Action'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import { useEffect, useState } from 'react'
import { useMultipleLanguageStore } from 'stores/commons'
import { useTranslation } from 'react-i18next'
import appConfig from 'configs/app'
import { getLocalStorage } from 'helpers/local-storage'
import countryLang from 'constants/language'

const MultipleLanguageDrawer = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const [currentLang, setCurrentLang] = useState(
    getLocalStorage('i18nextLng') || appConfig.defaultLang,
  )

  const [selectedLang, setSelectedLang] = useState({
    id: '',
    label: '',
    flag: '',
  })

  const {
    showMultipleLanguageDrawer,
    setShowMultipleLanguageDrawer,
    setLanguage,
  } = useMultipleLanguageStore((state) => ({
    showMultipleLanguageDrawer: state.showMultipleLanguageDrawer,
    setShowMultipleLanguageDrawer:
      state.setShowMultipleLanguageDrawer,
    setLanguage: state.setLanguage,
  }))

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = event.target.value
    setCurrentLang(val)
  }

  const handleLocaleChange = () => {
    i18n.changeLanguage(currentLang)
    setShowMultipleLanguageDrawer(false)
  }

  useEffect(() => {
    const selectedLanguage = countryLang.find(
      (language) => language.id === currentLang,
    )
    setSelectedLang(selectedLanguage)
    setLanguage(selectedLanguage)
  }, [currentLang])

  return (
    <ModalDrawer
      title={t('key_select_language')}
      isOpen={showMultipleLanguageDrawer}
      onClose={() => setShowMultipleLanguageDrawer(false)}
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
      sxDrawerContainer={{ padding: '16px 0px' }}
    >
      <Stack gap={2}>
        {countryLang.map((item, _index) => (
          <Stack
            key={`menu-language-${item.id}`}
            direction={'row'}
            sx={{
              textAlign: 'center',
              justifyContent: 'space-between',
              borderRadius: `6px`,
              background: theme.palette.background.secondary,
              padding: '12px',
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                textAlign: 'center',
                gap: 1,
                img: {
                  alignSelf: `center`,
                },
              }}
            >
              <img
                src={item.flag}
                width={'16px'}
                height={'16px'}
                alt={item.label}
              />
              <Typography>{item.label}</Typography>
            </Stack>
            <Radio
              checked={selectedLang?.id === item.id}
              onChange={handleChange}
              value={item.id}
              name="radio-buttons"
              sx={{ padding: '0px' }}
            />
          </Stack>
        ))}
        <ActionButton onClick={handleLocaleChange}>
          {t('save')}
        </ActionButton>
      </Stack>
    </ModalDrawer>
  )
}

export default MultipleLanguageDrawer
