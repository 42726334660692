import { useMediaQuery } from '@mui/material'
import StateImage from 'components/commons/StateImage'
import {
  CoinPriceLabel,
  CoinPriceValue,
  StyledCheck,
  TitleLabel,
  TitleLabelWrapper,
} from './styled'
import {
  StyledCoin,
  StyledDivider,
} from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import theme from 'src/theme'
import BottomDrawer from 'components/commons/BottomDrawer'
import PaymentModal from 'components/commons/PaymentModal/v2'
import Box from 'components/commons/Box'

interface ISuccessStateProps {
  isSuccessStateCoin: boolean
  submitAction: Function
  coinInfo: {
    previous_balance: number
    remaining_balance: number
    total_purchase: number
  }
}

export default function SuccessBuyCoin({
  isSuccessStateCoin,
  submitAction,
  coinInfo,
}: ISuccessStateProps) {
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )

  const SuccessBuyCoinContent = () => {
    return (
      <Box>
        <StateImage
          type="success-withdraw"
          sx={{
            marginBottom: '16px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <TitleLabelWrapper>
          <TitleLabel>Pembelian Berhasil</TitleLabel>
          <StyledCheck />
        </TitleLabelWrapper>

        <CoinPriceLabel>
          Total saldo koin
          <CoinPriceValue>
            <StyledCoin />
            {NumberBaseFormatter(coinInfo?.previous_balance)}
          </CoinPriceValue>
        </CoinPriceLabel>
        <CoinPriceLabel>
          Total harga
          <CoinPriceValue>
            <StyledCoin />
            {NumberBaseFormatter(coinInfo?.total_purchase)}
          </CoinPriceValue>
        </CoinPriceLabel>
        <CoinPriceLabel>
          Sisa saldo koin
          <CoinPriceValue>
            <StyledCoin />
            {NumberBaseFormatter(coinInfo?.remaining_balance)}
          </CoinPriceValue>
        </CoinPriceLabel>
        <StyledDivider />
      </Box>
    )
  }

  if (isDesktopResolution) {
    return (
      <PaymentModal
        showModal={isSuccessStateCoin}
        onCloseAction={() => submitAction()}
        submitLabel={'Selanjutnya'}
        onSubmitAction={() => submitAction()}
        content={<SuccessBuyCoinContent />}
      />
    )
  } else {
    return (
      <BottomDrawer
        showDrawer={isSuccessStateCoin}
        onCloseAction={() => submitAction()}
        submitLabel={'Selanjutnya'}
        onSubmitAction={() => submitAction()}
        content={<SuccessBuyCoinContent />}
      />
    )
  }
}
