import { Divider, styled } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import Coin from 'svgs/icons/Coin'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  display: 'inline',
  color: theme.palette.text.primary,
}))
type StatusProps = {
  statusCard?: string
}
export const StyledStatus = styled(Typography)<StatusProps>(
  ({ statusCard = '', theme }) => ({
    ...theme.typography.normalBold,
    padding: '4px 8px',
    color: 'white',
    display: 'inline',
    margin: '0px 0px 0px auto',
    float: 'right',
    backgroundColor:
      statusCard.toUpperCase() === 'PENDING'
        ? theme.palette.error.main
        : statusCard.toUpperCase() === 'PAID'
        ? theme.palette.success.main
        : theme.palette.text.tertiery,
    borderRadius: 6,
  }),
)
export const StyledDate = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  display: 'inline-block',
  color: theme.palette.text.secondary,
}))
export const LabelWrapper = styled(Box)({
  display: 'flex',
  heigh: 400,
  margin: '12px 0px',
})
export const LabelWrapperInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  heigh: 400,
  marginBottom: 18,
  padding: 8,
  border: `1px solid ${theme.palette.info.main}`,
  borderRadius: 8,
  svg: {
    fontSize: 24,
  },
}))
export const StyledInfoLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  color: theme.palette.text.primary,
}))
export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '16px',
  margin: 'auto',
  marginLeft: '0px',
}))
export const StyledBoldWhiteTitle = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.smallBold,
    color: theme.palette.text.primary,
    display: 'inline',
  }),
)
export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.background.tertiery,
}))
export const StyledCard = styled(Box)(({ theme }) => ({
  padding: 16,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.primary,
  borderRadius: 6,
  margin: '16px 0px',
  boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
}))
export const StyledProductList = styled(Box)(({}) => ({}))

type IImageProps = {
  imageUrl?: string
}
export const ProductImage = styled(Box)<IImageProps>(
  ({ imageUrl = '' }) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '42px',
    height: '42px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  }),
)
export const ProductTitleWrapper = styled(Box)({
  margin: 'auto 0px auto 16px',
  justifyContent: 'center',
  alignItems: 'center',
})

export const ProductTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
}))
export const ButtonPriceWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
type IPriceProps = {
  discount?: boolean
}
export const PriceWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    width: 300,
    flexDirection: 'column',
  },
}))

export const StyledTitlePrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  [theme.breakpoints.up('sm')]: {
    color: theme.palette.text.secondary,
    display: 'block',
  },
}))
export const StyledSubTitlePrice = styled(Typography)<IPriceProps>(
  ({ discount, theme }) => ({
    ...theme.typography.normalRegular,
    color: discount
      ? `${theme.palette.success.main} !important`
      : theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  }),
)
export const StyledPrice = styled(Typography)<IPriceProps>(
  ({ discount, theme }) => ({
    display: 'flex',
    ...theme.typography.normalBold,
    padding: '4px 8px',
    margin: '0px 0px 0px auto',
    float: 'right',
    color: discount
      ? `${theme.palette.success.main} !important`
      : theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      float: 'none',
      margin: '0px',
      padding: '0px',
    },
  }),
)
export const ButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))
export const ButtonWrapperModal = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between !important',
})
export const ButtonModalWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export const BuyCheckProductButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  borderRadius: 8,
  width: '100%',
  padding: '8px 8px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 6,
    width: 200,
  },
}))
export const BuyNowButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  borderRadius: 8,
  width: '100%',
  padding: '8px 8px',
  marginLeft: 6,
  [theme.breakpoints.up('sm')]: {
    width: 200,
  },
}))
export const CancelButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  borderRadius: 8,
  width: '100%',
  padding: '8px 8px',
  marginRight: 6,
  [theme.breakpoints.up('sm')]: {
    width: 200,
    marginLeft: 6,
  },
}))
export const DetailPaymentButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  width: '100%',
  padding: '8px 8px',
  marginRight: 6,
  [theme.breakpoints.up('sm')]: {
    width: 120,
  },
}))
export const DetailPaymentExpand = styled(Button)(({ theme }) => ({
  ...theme.typography.smallBold,
  padding: '0px !important',
  marginBottom: 12,
  marginTop: 8,
  justifyContent: 'start',
  svg: {
    fontSize: 16,
  },
}))

export const StyledCoinIcon = styled(Coin)({
  width: '14px',
  height: '14px',
  margin: '0px 4px',
})

export const CoinLabelDiscount = styled('div')(({ theme }) => ({
  color: theme.palette.text.tertiery,
  textDecoration: 'line-through',
  ...theme.typography.normalBold,
}))
export const TotalPriceWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: '-2px !important',
})
