import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLableVa,
  ItemsLableVaBold,
  VaDesc,
  VaDescBold,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { useTranslation } from 'react-i18next'

interface ISteps {
  title: string
  steps: Array<string>
}
interface IPendingStateProps {
  title: string
  paymentInstructionList: {
    expiry_date: string
    instructions: [
      {
        tab_name: string
        points: Array<ISteps>
        mobile_body: string
      },
    ]
    invoice_id: string
    payment_method_code: string
    payment_method_name: string
    total: number
    va: string
    va_name: string
  }
  vaStatus: {
    invoice_id: string
    payment_method_code: string
    status: string
    paid_at: string
    checkout_at: string
    payment_method_name: string
    total: number
  }
}
export default function PendingStateContent({
  title,
  paymentInstructionList,
  vaStatus,
}: IPendingStateProps) {
  const { t } = useTranslation()

  return (
    <Box>
      <StateImage
        type="pending-withdraw"
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <VaTitleLabel>{title}</VaTitleLabel>
      <VaDesc>
        {t('transactionVaStatusWidgetPendingDesc')}{' '}
        <VaDescBold>
          {dateFormatter({
            date: paymentInstructionList?.expiry_date,
            format: 'DD MMM YYYY, HH:mm',
          })}
        </VaDescBold>
      </VaDesc>
      <WrapperItemsPending>
        <ItemsLableVa>
          {t('transactionVAStatusDetailPendingWidgetTotalLabel')}
          <ItemsLableVaBold>
            Rp{NumberBaseFormatter(vaStatus?.total)}
          </ItemsLableVaBold>
        </ItemsLableVa>
        <ItemsLableVa>
          {t('transactionVAStatusDetailPendingWidgetCheckoutAtLabel')}
          <ItemsLableVaBold>
            {dateFormatter({
              date: vaStatus?.checkout_at,
              format: 'DD MMM YYYY, HH:mm',
            })}
          </ItemsLableVaBold>
        </ItemsLableVa>
      </WrapperItemsPending>
    </Box>
  )
}
