import { MouseEventHandler, ReactNode } from 'react'
import {
  DrawerProps,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import {
  CloseButton,
  FooterSection,
  HeaderSection,
  Wrapper,
} from './styled'
import CloseIcon from '@mui/icons-material/Close'

interface BottomDrawerProps extends Omit<DrawerProps, 'title'> {
  actions?: ReactNode[]
  onClose?: MouseEventHandler
  title?: string | ReactNode
  withoutCloseButton?: boolean
  closeButtonId?: string
  boxPadding?: string | number
  sxHeader?: SxProps
  onCloseIcon?: MouseEventHandler
  supportDesktop?: boolean
  hideHeader?: boolean
}

function BottomDrawer({
  children,
  closeButtonId,
  open,
  actions,
  onClose,
  title,
  withoutCloseButton,
  boxPadding = '16px',
  sxHeader,
  onCloseIcon,
  supportDesktop = false,
  hideHeader = false,
  ...rest
}: BottomDrawerProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <Wrapper
      supportdesktop={supportDesktop && 'true'}
      open={open}
      onClose={onClose}
      anchor="bottom"
      {...rest}
    >
      <Box sx={{ padding: boxPadding, backgroundColor: 'white' }}>
        {!hideHeader && (
          <HeaderSection
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              mb: '16px',
              ...sxHeader,
            }}
          >
            {title && (
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant={isDesktop ? 'h4Desk' : 'h4'}
                  color={theme.palette.text.primary}
                >
                  {title}
                </Typography>
              </Box>
            )}
            {!withoutCloseButton && (
              <Box>
                <CloseButton
                  id={`${closeButtonId}-${
                    isDesktop ? 'desktop' : 'mobile'
                  }`}
                  onClick={onClose || onCloseIcon}
                >
                  <CloseIcon
                    fontSize="small"
                    sx={{ color: theme.palette.primary.main }}
                  />
                </CloseButton>
              </Box>
            )}
          </HeaderSection>
        )}
        <Box>{children}</Box>
        {actions.length > 0 && (
          <FooterSection>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ width: '100%' }}
              spacing={2}
            >
              {actions.map((action, index) => (
                <Box key={index} sx={{ flex: 1, width: '100%' }}>
                  {action}
                </Box>
              ))}
            </Stack>
          </FooterSection>
        )}
      </Box>
    </Wrapper>
  )
}

BottomDrawer.defaultProps = {
  title: '',
  withoutCloseButton: false,
  children: null,
  actions: [],
}

export default BottomDrawer
