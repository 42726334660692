import { CardActions, CardMedia, Grid } from '@mui/material'
import Box from 'components/commons/Box'
import * as React from 'react'
import MainLogo from 'assets/logos/logo-mobile.svg'
import {
  VoucherButton,
  VoucherCardWrapper,
  VoucherContent,
  VoucherContentWrapper,
  VoucherDesc,
  VoucherDescText,
  VoucherHead,
  VoucherName,
} from './styled'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import { useTheme } from '@mui/material/styles'
import dateFormatter from 'helpers/formats/date'
import appConfig from 'configs/app'
import trackEvent from 'src/trackers'
import { useTranslation } from 'react-i18next'

interface IVoucherCard {
  description: string
  expired: string
  max_discount_amount: Number
  name: string
  rule: string
  is_grey_out: boolean
}
interface IVoucherCardProps {
  voucherListData: IVoucherCard
  voucherChecker: Function
  voucherDetailsHandler?: Function
  isLoading: boolean
  trackerData?: any
}
export default function VoucherCard({
  voucherListData,
  voucherChecker,
  voucherDetailsHandler,
  isLoading,
  trackerData,
}: IVoucherCardProps) {
  const { t } = useTranslation()

  const theme = useTheme()
  return (
    <VoucherCardWrapper
      onClick={() => {
        if (voucherDetailsHandler) {
          trackEvent.paymentCart(`select_voucher`, trackerData, {
            voucher_code: voucherListData?.rule,
          })
          !isLoading ? voucherDetailsHandler(voucherListData) : ''
        }
      }}
      sx={{
        maxWidth: 448,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          style={{ zIndex: 1 }}
          component="img"
          image={
            voucherListData?.max_discount_amount !== undefined &&
            voucherListData?.expired !== undefined
              ? `${appConfig.assetBaseUrl}/voucher/voucher_2desc_${
                  voucherListData.is_grey_out ? 'disabled' : 'active'
                }.png`
              : voucherListData?.max_discount_amount !== undefined ||
                voucherListData?.expired !== undefined
              ? `${appConfig.assetBaseUrl}/voucher/voucher_1desc_${
                  voucherListData.is_grey_out ? 'disabled' : 'active'
                }.png`
              : `${appConfig.assetBaseUrl}/voucher/voucher_0desc_${
                  voucherListData.is_grey_out ? 'disabled' : 'active'
                }.png`
          }
        />
        <VoucherContent>
          <VoucherContentWrapper>
            <VoucherHead container>
              <Grid item sm={8} xs={8}>
                <VoucherName>{voucherListData.name}</VoucherName>
              </Grid>
              <Grid item sm={4} xs={4}>
                <MainLogo />
              </Grid>
            </VoucherHead>
            {voucherListData?.max_discount_amount !== undefined && (
              <VoucherDesc>
                <MonetizationOnOutlinedIcon
                  style={{
                    color: theme.palette.tiptip[500],
                    marginRight: 3,
                  }}
                />
                <VoucherDescText>
                  Max. Discount {voucherListData.max_discount_amount}
                </VoucherDescText>
              </VoucherDesc>
            )}
            {voucherListData?.expired !== undefined && (
              <VoucherDesc>
                <AccessTimeIcon
                  style={{
                    color: theme.palette.tiptip[500],
                    marginRight: 3,
                  }}
                />
                <VoucherDescText>
                  {t('voucherInboxScreenExpired')}{' '}
                  {dateFormatter({
                    date: voucherListData.expired,
                    format: 'D MMMM YYYY',
                  })}
                </VoucherDescText>
              </VoucherDesc>
            )}
            <CardActions>
              <VoucherButton
                disabled={isLoading || voucherListData.is_grey_out}
                variant="contained"
                onClick={(event) => {
                  voucherChecker(voucherListData?.rule, true)
                  event.stopPropagation()
                }}
              >
                {t('voucherInboxScreenVoucherButton')}
              </VoucherButton>
            </CardActions>
          </VoucherContentWrapper>
        </VoucherContent>
      </Box>
    </VoucherCardWrapper>
  )
}
