import * as React from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import theme from '../theme'
import createEmotionCache from '../createEmotionCache'
import { StoreProvider } from 'easy-peasy'
import store from 'stores/index'
import { Amplify } from 'aws-amplify'
import config from '../configs/amplify'
import '../style.css'
import { initAnalytics } from 'helpers/analytics'
import { initLogger, sendLog } from 'helpers/log'
import { AuthProvider } from 'contexts/AuthContext'
import { CommunityProvider } from 'contexts/CommunityContext'
import { RoutingProvider } from 'contexts/RoutingContext'
import ErrorBoundary from 'containers/commons/ErrorBoundary'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Script from 'next/script'
import * as fbq from 'helpers/facebook-pixel'
import * as moe from 'helpers/moengage'
import { GTM_ID, pageview } from 'helpers/google-tag-manager'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import HtmlHead from 'helpers/htmlHead'
import {
  FlagProvider,
  UnleashClient,
} from '@unleash/proxy-client-react'
import flagConfig from 'configs/feature-flag'
import NotificationBar from 'containers/commons/NotificationBar'
import apiConfig from 'configs/api'
import i18n, { supportedLanguages } from 'i18n'
import appConfig from 'configs/app'
import { getLocalStorage } from 'helpers/local-storage'

initLogger()
initAnalytics()

Amplify.configure({
  ...config,
  ssr: true,
})

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function MyApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props

  const router = useRouter()
  const queryClient = new QueryClient()

  const registerServiceWorker = async () => {
    const moeEnabled =
      process.env.NEXT_PUBLIC_MOENGAGE_ENABLED === 'true'
    if (moeEnabled && 'serviceWorker' in navigator) {
      try {
        await navigator.serviceWorker.register('/serviceworker.js')
      } catch (error) {
        sendLog(`Registration service worker failed with ${error}`)
      }
    }
  }

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    registerServiceWorker()
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  useEffect(() => {
    const lang =
      getLocalStorage('i18nextLng') || appConfig.defaultLang
    if (
      lang &&
      supportedLanguages.includes(lang) &&
      lang !== i18n.language
    ) {
      i18n.changeLanguage(lang)
    }
  }, [])

  //clients unleash config
  const client = new UnleashClient(flagConfig)

  return (
    <StoreProvider store={store}>
      <CacheProvider value={emotionCache}>
        <RoutingProvider router={router}>
          <HtmlHead
            description="TipTip adalah Marketplace karya digital, sesi live, dan e-tiket event offline untuk kreator dan komunitas."
            title="TipTip.id - TipTip adalah Marketplace karya digital, sesi live, dan e-tiket event offline untuk kreator dan komunitas."
            image={`https://${process.env.NEXT_PUBLIC_APP_DOMAIN}/icon/tiptip-meta-logo.png`}
            keywords="Live streaming, Sesi Online, Sesi, Session, Kelas Online, Aplikasi Streaming, Creator, Supporter, Tip, Tipping, Webinar, Sesi Private, Sharing Session, Kelas Online, Kelas Workout, Kelas Yoga, Income, Penghasilan Tambahan, Jumpa Fans, Meet and Greet, Fans"
            link="https://www.tiptip.id/"
          />
          <Head>
            {/* link manifest.json */}
            <link rel="manifest" href="/manifest.json" />
            <title>
              TipTip.id - Marketplace for Creators &amp; Communities
            </title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, target-densityDpi=device-dpi"
            />
            <meta
              property="og:site_name"
              content="TipTip TV"
              key="og-sitename"
            />
            <meta
              property="og:type"
              content="website"
              key="og-type"
            />
            {/* Twitter meta tags */}
            <meta
              name="twitter:card"
              content="summary_large_image"
              key="twitter-card"
            />
            <meta
              name="twitter:site"
              content="@tiptip_tv"
              key="twitter-site"
            />
            <meta
              name="twitter:creator"
              content="@tiptip_tv"
              key="twitter-creator"
            />
            <meta
              name="twitter:image:alt"
              content="TipTip TV"
              key="twitter-image-alt"
            />
            <meta
              name="twitter:domain"
              content="https://tiptip.id"
              key="twitter-domain"
            />
            {/* End Twitter meta tags */}
            <meta
              name="facebook-domain-verification"
              content="uff5el5pebz6nrokq2yrdvos1eanzt"
            />
            <link rel="shortcut icon" href="/icon/favicon.ico"></link>
            <link
              href="/icon/favicon-16x16.png"
              rel="icon"
              type="image/png"
              sizes="16x16"
            />
            <link
              href="/icon/favicon-32x32.png"
              rel="icon"
              type="image/png"
              sizes="32x32"
            />
            <link
              href="/icon/favicon-96x96.png"
              rel="icon"
              type="image/png"
              sizes="96x96"
            />
            <link
              rel="apple-touch-icon"
              href="/apple-touch-icon.png"
            />
            <script
              src="https://cdn.bitmovin.com/player/web/8/bitmovinplayer.js"
              type="text/javascript"
              async
            ></script>
            <script
              type="text/javascript"
              src="//static.queue-it.net/script/queueclient.min.js"
            ></script>
            <script
              data-queueit-intercept-domain={apiConfig.baseApiDomain}
              data-queueit-spa="true"
              data-queueit-intercept="true"
              data-queueit-c="tiptip"
              type="text/javascript"
              src="//static.queue-it.net/script/queueconfigloader.min.js"
            ></script>
          </Head>
          <Script
            id="Freshchat-js-sdk"
            src="https://wchat.freshchat.com/js/widget.js"
            async
          />
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
            }}
          />
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_ID}');
              `,
            }}
          />
          {moe.isEnabled && (
            <Script
              type="text/javascript"
              strategy="afterInteractive"
              id="moengage-sdk"
              dangerouslySetInnerHTML={{
                __html: `
         (function(i, s, o, g, r, a, m, n) {i.moengage_object = r;t = {};q = function(f) {return function() {(i.moengage_q = i.moengage_q || []).push({ f: f, a: arguments });};};(f = ["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute",]),(h = { onsite: ["getData", "registerCallback"] });for (k
          in f) {t[f[k]] = q(f[k]);}for (k in h)for (l in h[k]) {null == t[k] && (t[k] = {}), (t[k][h[k][l]] = q(k + "." + h[k][l]));}a = s.createElement(o);m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);i.moe =i.moe ||function() {n = arguments[0];return t;};a.onload = function() {if (n) {i[r] = moe(n);}};})(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");

            Moengage = moe({
            app_id:"${moe.MOENGAGE_APP_ID}",
            debug_logs: ${moe.handleDebug()},
            enableSPA: true
            });
            `,
              }}
            />
          )}
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <CommunityProvider>
                <ThemeProvider theme={theme}>
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                  <ErrorBoundary>
                    <FlagProvider
                      unleashClient={client}
                      startClient={false}
                    >
                      <NotificationBar />
                      <Component key={router.asPath} {...pageProps} />
                    </FlagProvider>
                  </ErrorBoundary>
                </ThemeProvider>
              </CommunityProvider>
            </AuthProvider>
          </QueryClientProvider>
        </RoutingProvider>
      </CacheProvider>
    </StoreProvider>
  )
}

export default MyApp
