import { debounce } from '@mui/material'
import { styled, FormControl } from '@mui/material'

import Typography from 'components/commons/Typography'
import Grid from 'components/commons/Grid'
import MessageBox from 'components/commons/MessageBox'
import { Form } from 'components/commons/Form'
import ActionButton from 'components/commons/Button/Action'
import {
  StyledError,
  StyledInputLabel,
  StyledTextField,
} from 'components/commons/Form/styled'

import { useForm, Controller } from 'react-hook-form'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  getLocalStorage,
  setLocalStorage,
  setWithExpiry,
} from 'helpers/local-storage'
import isEmpty from 'helpers/is-empty'
import { addDays } from 'helpers/date-time'
import { setSessionStorage } from 'helpers/session-storage'
import { redirectWithReferrer } from 'helpers/redirector'
import {
  phoneNumberOptions,
  phoneNumberOptionsForOther,
} from 'constants/phone-number'
import { useAuthStore } from 'stores/domains/User'
import { requestOtp } from 'services/user'
import { verificationMethodConst } from 'constants/common'
import { authErrorConst } from 'constants/auth-error'
import { renderErrorMessage } from 'helpers/auth/error-auth'
import trackEvent from 'src/trackers'
import { setPeopleProperties } from 'helpers/analytics'
import { useProfilePhone } from 'hooks/domains/User'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'
import CountrySelect from 'components/domains/User/SelectCountryCodes'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import { useTranslation } from 'react-i18next'

//Component Style
const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.0015em',
}))

type FormData = {
  phoneNumber: string
  areaCode: string
}

function PhoneSubmitDrawer() {
  const { t } = useTranslation()
  const [isRequesting, setIsRequesting] = useState(false)

  const idToken = getIdToken()
  const hidePhoneNumberDrawer = getLocalStorage(
    'hidePhoneNumberDrawer',
  )
  const ignorePhoneNumberVerification = getLocalStorage(
    'ignorePhoneNumberVerification',
  )
  const { pathname, query, isReady } = useRouter()
  const trackerData = { pathname, query }

  const {
    data: profilePhone,
    refetch: refetchPhone,
    isLoading,
  } = useProfilePhone()

  const { setVerificationMethod, countryData, resetCountryData } =
    useAuthStore((state) => ({
      setVerificationMethod: state.setVerificationMethod,
      countryData: state.countryData,
      resetCountryData: state.resetCountryData,
    }))

  const [validationPhoneRules, setValidationPhoneRules] = useState(
    countryData.dial_code === '+62'
      ? phoneNumberOptions
      : phoneNumberOptionsForOther,
  )

  const {
    setShowPhoneNumberDrawer,
    setPhoneNumberVerification,
    resetPhoneNumberVerification,
  } = useStoreActions((actions) => actions.phoneNumber)

  const signUpBonusAction = useStoreActions(
    (actions) => actions.signUpBonusBanner,
  )

  const { showPhoneNumberDrawer, phoneNumberVerification } =
    useStoreState((actions) => actions.phoneNumber)

  const [messageBox, setMessageBox] = useState('')
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
    setError,
  } = useForm<FormData>({
    mode: 'onChange',
  })

  const renderPhoneErrorMessage = () => {
    const min =
      countryData.country_code === 'ID'
        ? phoneNumberOptions.minLength
        : phoneNumberOptionsForOther.minLength
    const max =
      countryData.country_code === 'ID'
        ? phoneNumberOptions.maxLength
        : phoneNumberOptionsForOther.maxLength
    switch (errors?.phoneNumber?.type) {
      case 'required':
        return 'Nomor HP tidak boleh kosong.'
      case 'minLength':
      case 'maxLength':
        return `Nomor HP minimal ${min} dan maksimal ${max} karakter.`
      case 'custom':
        return errors.phoneNumber.message
      case 'pattern':
      default:
        return 'Masukan nomor HP yang valid.'
    }
  }

  const handleClosePhoneNumberDrawer = () => {
    trackEvent.user('cancel_phone_number_fill_in', trackerData)
    setWithExpiry('hidePhoneNumberDrawer', 'true', addDays(3))
    setShowPhoneNumberDrawer(false)
  }

  const handleIgnorePhoneNumberVerification = () => {
    trackEvent.user('cancel_phone_number_fill_in', trackerData)
    setLocalStorage('ignorePhoneNumberVerification', 'true')
    setShowPhoneNumberDrawer(false)
  }

  const handleSubmitPhoneNumber = async (method: number) => {
    const phoneNumber = getValues('phoneNumber')
    const areaCode = getValues('areaCode')

    const phoneWithAreaCode = `${areaCode}${+phoneNumber}`
    setSessionStorage('verifyPhone', phoneWithAreaCode)
    setLocalStorage('countryData', countryData)
    trackEvent.user('submit_phone_number', trackerData, {
      modal_name: 'PHONE_NUMBER',
      phone_number: phoneWithAreaCode,
    })
    setPeopleProperties({
      phone_number: phoneWithAreaCode,
    })
    setIsRequesting(true)
    try {
      const response = await requestOtp({
        action: 'ADD_PHONE',
        delivery_method: verificationMethodConst[method],
        username: phoneWithAreaCode,
      })
      if (response?.data?.code === 'SUCCESS') {
        resetCountryData()
        setVerificationMethod(0)
        setShowPhoneNumberDrawer(false)
        let isFrom =
          query?.source === 'CONTENT_HUB'
            ? `${'isFrom=CONTENT_HUB'}`
            : ''
        redirectWithReferrer(
          `/phone-verification?${isFrom}&source=${pathname}&source_action=trigger_submit_phone_registration`,
          true,
        )
      }
      setIsRequesting(false)
    } catch (error) {
      setIsRequesting(false)

      if (authErrorConst?.[error.response.data.code]) {
        const errMessage = renderErrorMessage(
          error.response.data.code?.toUpperCase(),
          {
            seconds: error.response.data?.data?.retry_in_secs,
            defaultMessage: error.response.data.message,
          },
        )
        setError('phoneNumber', {
          type: 'custom',
          message: errMessage,
        })
        return
      } else if (
        error.response?.data?.code === 'PHONE_NUMBER_ALREADY_USED'
      ) {
        setMessageBox(error.response.data.message)
      } else {
        setMessageBox(
          error?.response?.data?.message
            ? error.response.data.message
            : t('key_something_wrong_try_again'),
        )
      }
    }
  }

  useEffect(() => {
    if (profilePhone) {
      setPhoneNumberVerification(profilePhone)
    }

    return () => {
      resetPhoneNumberVerification()
    }
  }, [profilePhone])

  useEffect(() => {
    if (showPhoneNumberDrawer) setMessageBox('')
  }, [showPhoneNumberDrawer])

  useEffect(() => {
    if (phoneNumberVerification?.verified === true) {
      setShowPhoneNumberDrawer(false)
    } else if (
      phoneNumberVerification?.verified === false &&
      isEmpty(hidePhoneNumberDrawer) &&
      isEmpty(ignorePhoneNumberVerification) &&
      !isEmpty(idToken) &&
      pathname === '/'
    ) {
      signUpBonusAction.getBonusCoins()
    }
  }, [phoneNumberVerification, idToken, pathname])

  useEffect(() => {
    if (isReady && !isGuestMode()) refetchPhone()
  }, [isReady])

  if (!isLoading) {
    return (
      <ModalDrawer
        title={`Jadi yang paling update di Tiptip`}
        hideCloseButton={true}
        isOpen={showPhoneNumberDrawer}
        onClose={debounce(handleClosePhoneNumberDrawer, 250)}
        isLoading={isLoading}
      >
        <Grid
          id="c-users-phone-number-drawer-grid-container"
          container
          spacing={2}
        >
          <Grid id="c-users-phone-number-drawer-grid-item2" item>
            <SubTitle id="c-users-sub-title">
              Tambahkan nomor handphone kamu buat dapetin info terbaru
              dan penawaran menarik dari TipTip.
            </SubTitle>
          </Grid>
          <Grid
            id="c-users-phone-number-drawer-grid-item3"
            item
            xs={12}
          >
            <Form id="f-users-phone-number-drawer">
              <FormControl variant="standard" fullWidth>
                <StyledInputLabel shrink id="c-users-handphone">
                  Nomor HP
                </StyledInputLabel>
                <Grid
                  id="c-users-handphone-grid-container"
                  container
                  spacing={1}
                >
                  <Grid
                    id="c-users-country-grid-item"
                    item
                    xs={3}
                    sx={{ marginTop: '20px' }}
                  >
                    <CountrySelect
                      control={control}
                      value={getValues(`areaCode`)}
                      onChanged={(code) => {
                        setValidationPhoneRules(
                          code === '+62'
                            ? phoneNumberOptions
                            : phoneNumberOptionsForOther,
                        )
                        setValue('areaCode', code)
                      }}
                      position="fixed"
                    />
                  </Grid>
                  {errors?.areaCode && (
                    <Grid
                      id="c-users-handphone-error-grid-item"
                      item
                      xs={12}
                    >
                      {errors?.areaCode?.type === 'required' && (
                        <StyledError>
                          Kode Area tidak boleh kosong.
                        </StyledError>
                      )}
                    </Grid>
                  )}
                  <Grid
                    id="c-users-handphone-grid-item"
                    item
                    xs={9}
                    sx={{ marginTop: '20px' }}
                  >
                    <StyledInputLabel id="c-users-input-label">
                      &nbsp;
                    </StyledInputLabel>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      rules={validationPhoneRules}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          id="i-users-handphone"
                          fullWidth
                          placeholder="Masukan Nomor HP"
                          type="number"
                          error={errors?.phoneNumber ? true : false}
                        />
                      )}
                    />
                  </Grid>
                  {errors?.phoneNumber && (
                    <Grid
                      id="c-users-handphone-error-grid-item"
                      item
                      xs={12}
                    >
                      <StyledError>
                        {renderPhoneErrorMessage()}
                      </StyledError>
                    </Grid>
                  )}
                </Grid>
                {!isEmpty(messageBox) && (
                  <MessageBox
                    open={!isEmpty(messageBox)}
                    onClose={() => setMessageBox('')}
                    isError={true}
                    message={messageBox}
                  />
                )}
                <ActionButton
                  disabled={!isValid || isRequesting}
                  onClick={() => handleSubmitPhoneNumber(0)}
                  sx={{
                    marginTop: '16px',
                  }}
                >
                  Simpan
                </ActionButton>
              </FormControl>
              <ActionButton
                buttonVariant="nude"
                fullWidth
                disableElevation
                onClick={debounce(
                  handleIgnorePhoneNumberVerification,
                  250,
                )}
              >
                Nanti Saja
              </ActionButton>
            </Form>
          </Grid>
        </Grid>
      </ModalDrawer>
    )
  }

  return null
}

export default PhoneSubmitDrawer
