import { StateCreator } from 'zustand'
import _get from 'lodash/get'
import _merge from 'lodash/merge'
import _pick from 'lodash/pick'

import {
  CreatorClass,
  IProfileData,
  IProfileDefault,
  IProfileUserStore,
  ISocialMedia,
} from './interface'
import { sendLog } from 'helpers/log'
import { setLocalStorage } from 'helpers/local-storage'
import imageResizer from 'helpers/image-resizer'
import { getCookie, setCookie } from 'helpers/cookie'
import isEmpty from 'helpers/is-empty'

export * from './interface'

const DEFAULT_SOCIAL_MEDIA: ISocialMedia = {
  facebook: {},
  instagram: {},
  linkedin: {},
  tiktok: {},
  twitter: {},
  youtube: {},
}

export const DEFAULT_PROFILE_DATA: IProfileData = {
  user_id: '',
  name: '',
  email: '',
  phone: '',
  username: '',
  dob: '',
  gender: '',
  country_code: '',
  avatar: '',
  referral_code: '',
  referrer_code: '',
  referral_codes: [],
  is_external_provider: false,
  is_password_changeable: false,
  callName: '',
  isCreator: true,
  bio: '',
  contact_phone: '',
  full_name: '',
  header_image: '',
  is_allow_iap: false,
  is_allow_subscription: false,
  preference: {
    category: [],
  },
  profile_image_thumbnail: '',
  social_media: DEFAULT_SOCIAL_MEDIA,
  creator: {
    name: '',
    category: 0,
    category_name: '',
    status: '',
    reason: '',
    creator_id: '',
    creator_user_id: '',
    verified: false,
    header_image: '',
    profile_image_thumbnail: '',
    social_media: DEFAULT_SOCIAL_MEDIA,
    self: false,
    creator_class: 'PERSONAL',
  },
  role: 'personal',
}
const INITIAL_DATA: IProfileDefault = {
  isLoading: false,
  isError: false,
  errorMessage: undefined,
  errorCode: undefined,
  username: '',
  isCreator: true,
  profileData: DEFAULT_PROFILE_DATA,
  isLoaded: false,
  isNewCreatorDrawer: false,
}

const getErrorMessage = (error: Error) => {
  return _get(
    error,
    'response.data.message',
    error?.message || 'Ups, terjadi kesalahan',
  )
}

const getErrorCode = (error: Error) => {
  return _get(error, 'response.data.code', '')
}

const getAbsoluteData = (data: any): IProfileData => {
  const creator = _get(data, 'creator', {})
  const pickCreator = _pick(creator, ['bio', 'name', 'social_media'])
  return _merge(data, pickCreator)
}

export const mappingCreatorClass = (creatorClass: CreatorClass) => {
  switch (creatorClass) {
    case 'BUSINESS':
      return 'Badan Usaha'
    case 'CREATOR':
      return 'Kreator'
    case 'PERSONAL':
    default:
      return 'Personal'
  }
}

export const mappingClassName = (creatorClass: CreatorClass) => {
  switch (creatorClass) {
    case 'BUSINESS':
      return 'Badan Usaha'
    default:
      return 'Creator'
  }
}

/* eslint-disable-next-line no-unused-vars */
const resetters: (() => void)[] = []

export const createUserProfileStore: StateCreator<
  IProfileUserStore
> = (set) => {
  resetters.push(() => set(INITIAL_DATA))
  return {
    ...INITIAL_DATA,
    setLoading(loading) {
      return {
        isLoading: loading,
      }
    },
    setUserProfile(payload) {
      set(() => {
        const data = getAbsoluteData(payload)
        const creator = data?.creator
        const callName = data?.name || data?.username
        const headerImage =
          data?.header_image || creator?.header_image
        const avatar = imageResizer(headerImage, '500w')
        const role = mappingCreatorClass(creator?.creator_class)
        if (isEmpty(getCookie('username')) && data?.username)
          setCookie('username', data?.username)

        setLocalStorage('userId', { id: data?.user_id })

        return {
          isLoading: false,
          isLoaded: true,
          profileData: {
            ...data,
            callName,
            avatar,
            header_image: headerImage,
            role,
            isCreator: true,
          },
        }
      })
    },
    setUsername(data) {
      set(() => {
        return {
          isLoading: false,
          username: data?.username,
        }
      })
    },
    setError(error) {
      sendLog(error?.message)

      const errorCode = getErrorCode(error)
      set({
        errorCode,
        isError: true,
        errorMessage: getErrorMessage(error),
        isLoading: false,
      })
    },
    setNewCreatorDrawer(visible) {
      set({
        isNewCreatorDrawer: visible,
      })
    },
  }
}

export const resetUserProfileStore = () =>
  resetters.forEach((resetter) => resetter())
