import { useState } from 'react'
import { useRouter } from 'next/router'
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  styled,
  useTheme,
} from '@mui/material'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import authConfig from 'configs/auth'
import appConfig from 'configs/app'

import isEmpty from 'helpers/is-empty'
import redirect from 'helpers/redirect'
import { getIdToken } from 'helpers/auth'
import {
  openInNewTab,
  redirectWithReferrer,
} from 'helpers/redirector'
import { setUserDevice } from 'helpers/user-device'
import trackEvent from 'src/trackers'

import { useStoreActions, useStoreState } from 'stores/hooks'
import { useProfileUserStore } from 'stores/domains/User'

import ProfileAvatar from 'components/commons/ProfileAvatar'
import BottomDrawer from 'components/commons/BottomDrawer/v2'
import { NewFeatureBox } from 'components/commons/NewFeatureBox'
import { useFeatureFlags } from 'helpers/feature-flag'
import { useCommunityStore } from 'stores/domains/Social'
import CreateMenuList from 'components/commons/Header/CreateMenuList'

import MainLogo from 'assets/logos/logo-mobile.svg'
import CloseIcon from '@mui/icons-material/Close'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'

const StyledContainer = styled(Drawer)(({ theme }) => ({
  width: '80vw',
  height: '100vh',
  '& .MuiPaper-root': {
    background: theme.palette.background.primary,
  },
}))

const DrawerHeader = styled(Stack)({
  width: '100%',
})

const StyleAppBar = styled(AppBar)(({ theme }) => ({
  right: 'unset',
  maxWidth: '100%',
  background: theme.palette.background.primary,
  boxShadow: 'none',
  position: 'absolute',
  '& .MuiToolbar-root': {
    justifyContent: 'space-between',
  },
  padding: '16px',
}))

const StyledButton = styled(Button)({
  width: '85vw',
  height: '45px',
  borderRadius: '4px',
})

const StyledOutlineButton = styled(StyledButton)({
  marginBottom: '12px',
  width: '70vw',
})

const StyledPrimaryButton = styled(StyledButton)(({ theme }) => ({
  background: `${theme.colors.gradient1} !important`,
  '&:hover': {
    opacity: '75%',
  },
  width: '70vw',
}))

const DrawerContentContainer = styled(Box)({
  width: '100%',
  marginTop: '88px',
})
const DrawerContent = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const StyledToolbar = styled(Toolbar)({
  padding: '0px !important',
})

const StyledProfileContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.secondary,
  borderRadius: '12px',
  padding: '12px',
  width: '70vw',
  margin: '0 16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '30px',
}))

const StyledProfileName = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
}))

export const HeaderMenu = () => {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const idToken = getIdToken()
  const theme = useTheme()

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))
  const { renderHeaderMenu } = useStoreState(
    (state) => state.screenLayout,
  )
  const { setRenderHeaderMenu } = useStoreActions(
    (action) => action.screenLayout,
  )
  const { communityInfo } = useCommunityStore((state) => ({
    communityInfo: state.communityInfo,
  }))

  const isNewFeatureCommunity = useFeatureFlags(
    'new-feature-community',
  )

  const [isOpenContent, setIsOpenContent] = useState<boolean>(false)

  const redirectURL = (_url, isNewTab = false) => {
    setRenderHeaderMenu(false)
    setUserDevice()
    if (isNewTab) {
      openInNewTab(_url)
    } else {
      redirect(_url)
    }
  }

  const handleRedirectLogin = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent.home('click_sign_in', trackerData)
    redirectURL(
      redirectWithReferrer(authConfig.loginPath, true, false),
    )
  }

  const handleRedirectSignUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent.home('click_sign_up', trackerData)
    redirectURL(
      redirectWithReferrer(authConfig.registerPath, true, false),
    )
  }

  const handleClickBecomeCreator = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (isEmpty(idToken)) {
      trackEvent.home('click_learn_more', trackerData)
      openInNewTab(
        `${appConfig.creatorLandingUrl}?source=${pathname}&source_action=click_learn_more`,
      )
    } else {
      trackEvent.home('click_creator_registration', trackerData)
      redirect(
        `${appConfig.creatorRegistrationUrl}?source=${pathname}&source_action=click_creator_registration`,
      )
    }
  }

  return (
    <StyledContainer
      anchor="bottom"
      open={renderHeaderMenu}
      PaperProps={{
        sx: {
          width: '80vw',
          height: '100vh',
          right: '-1px',
          left: 'unset',
          background: theme.palette.background.primary,
        },
      }}
      onClose={() => setRenderHeaderMenu(false)}
    >
      <DrawerHeader
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyleAppBar>
          <StyledToolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, p: 0 }}
              onClick={() => {
                setRenderHeaderMenu(false)
                trackEvent.home('click_home', trackerData)
                redirect('/')
              }}
            >
              <MainLogo />
            </IconButton>
            <IconButton
              id="b-search-close"
              onClick={() => setRenderHeaderMenu(false)}
            >
              <CloseIcon
                sx={{
                  color: 'white',
                }}
              />
            </IconButton>
          </StyledToolbar>
        </StyleAppBar>
      </DrawerHeader>
      <DrawerContentContainer>
        <DrawerContent>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            {!isEmpty(idToken) && (
              <StyledProfileContainer>
                <ProfileAvatar
                  alt="image-profile-menu"
                  src={profileData.avatar}
                />
                <Stack
                  sx={{
                    marginLeft: '12px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    wordWrap: 'break-word',
                    display: 'block',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <StyledProfileName>
                    {profileData.username}
                  </StyledProfileName>
                  <Typography
                    sx={{
                      ...theme.typography.smallBold,
                      color: theme.palette.tiptip[500],
                      marginTop: '6px',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      redirectURL('/setting')
                    }}
                  >
                    View Profile
                  </Typography>
                </Stack>
              </StyledProfileContainer>
            )}
            <Button
              buttonVariant="nude"
              sx={{ marginTop: '0px !important' }}
              onClick={() => redirectURL('/')}
            >
              Home
            </Button>
            {!isEmpty(idToken) && (
              <>
                <Button
                  buttonVariant="nude"
                  onClick={() => {
                    trackEvent.home('click_library', trackerData)
                    redirectURL(
                      `/library?source=${pathname}&source_action=trigger_my_library_tab`,
                    )
                  }}
                >
                  Pembelian
                </Button>
                {!profileData.isCreator && (
                  <Button
                    buttonVariant="nude"
                    onClick={handleClickBecomeCreator}
                  >
                    Become a Creator
                  </Button>
                )}
              </>
            )}

            {!isEmpty(idToken) && (
              <>
                {profileData.isCreator ? (
                  <>
                    {communityInfo.is_allowed && (
                      <Stack direction={'row'} alignItems={'center'}>
                        <Button
                          buttonVariant="nude"
                          onClick={() => {
                            redirectURL(
                              `/community?source=${pathname}&source_action=click_my_community`,
                            )
                          }}
                          sx={{ marginBottom: 0 }}
                        >
                          Community
                        </Button>
                        {isNewFeatureCommunity && (
                          <NewFeatureBox
                            sxContainer={{
                              position: 'absolute',
                              right: '15%',
                            }}
                          />
                        )}
                      </Stack>
                    )}
                    <Button
                      buttonVariant="nude"
                      onClick={() => {
                        redirectURL(appConfig.contentHubMainUrl, true)
                      }}
                    >
                      Content Hub
                    </Button>
                  </>
                ) : (
                  <Button
                    buttonVariant="nude"
                    onClick={() => {
                      redirectURL(appConfig.promoterUrl, true)
                    }}
                  >
                    Promoter
                  </Button>
                )}
              </>
            )}

            <Button
              buttonVariant="nude"
              onClick={() =>
                redirectURL(appConfig.aboutTiptipUrl, true)
              }
            >
              About TipTip
            </Button>

            {!isEmpty(idToken) && profileData.isCreator && (
              <Button
                buttonVariant="nude"
                onClick={() => setIsOpenContent(true)}
              >
                Create
              </Button>
            )}

            {isEmpty(idToken) && (
              <Stack sx={{ position: 'absolute', bottom: '20px' }}>
                <StyledOutlineButton
                  buttonVariant="outlined"
                  onClick={handleRedirectLogin}
                >
                  Sign In
                </StyledOutlineButton>
                <StyledPrimaryButton onClick={handleRedirectSignUp}>
                  Sign Up
                </StyledPrimaryButton>
              </Stack>
            )}
          </Stack>
        </DrawerContent>
      </DrawerContentContainer>
      <BottomDrawer
        open={isOpenContent}
        title="Buat Konten"
        onClose={() => setIsOpenContent(false)}
      >
        <CreateMenuList
          p={0}
          divider={
            <Divider
              sx={(theme) => ({
                background: theme.palette.background.tertiery,
              })}
            />
          }
        />
      </BottomDrawer>
    </StyledContainer>
  )
}
