import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { CssBaseline, Container, styled } from '@mui/material'

import Loader from 'components/commons/Layout/Loader'

import { getLocalStorage } from 'helpers/local-storage'
import { getIdToken } from 'helpers/auth'
import { useStoreState } from 'stores/hooks'

import SignUpBonusBanner from 'components/commons/Banner'
import PhoneSubmitDrawer from 'containers/domains/User/PhoneSubmitDrawer'

const StyledContainer = styled(Container)(({ theme }) => ({
  background: theme.palette.background.primary,
}))

function Layout({
  header,
  footer,
  children,
  isLoading,
  isLandscape,
}): JSX.Element {
  const [minHeight, setMinHeight] = useState('calc(100vh)')

  const authMethod = getLocalStorage('authMethod')

  // Begin of Sign Up Bonus
  const { pathname } = useRouter()
  const whitelistedPathsNames = useMemo(
    () => [
      '/',
      '/browse',
      '/session/[session_id]',
      '/content/[content_uid]',
      '/p/[username]',
      '/sign-up',
      '/login',
      '/setting/account/information',
    ],
    [],
  )

  const signUpBonusBanner = useStoreState(
    (state) => state.signUpBonusBanner,
  )
  const idToken = getIdToken()
  const isGuest =
    !idToken && // not logged in
    whitelistedPathsNames.includes(pathname) //  on the whitelisted page
  const elBonusBanner = <SignUpBonusBanner />

  // Path for new user to show the banner sign up coin bonus
  const isNewUser = ['/user/post-registration'].includes(pathname)
  // End of path for new user

  const calculateContentHeight = () => {
    let height = 0
    if (header && header.component && !header.isSticky) {
      height += 60
    }
    if (footer && footer.component && !footer.isSticky) {
      height += 60
    }

    if (isGuest && signUpBonusBanner.elementHeight >= 1) {
      height += signUpBonusBanner.elementHeight
    }

    return `calc(100vh - ${height}px)`
  }

  useEffect(() => {
    setMinHeight(calculateContentHeight())
  }, [])

  useEffect(() => {
    setMinHeight(calculateContentHeight())
  }, [header, footer, elBonusBanner])

  const renderMarginTop = () => {
    if (header?.inLogin) {
      return 128
    }
    if (header?.appBanner) {
      return 72
    }
    return 0
  }

  return (
    <>
      <CssBaseline />

      <StyledContainer
        maxWidth={!isLandscape ? 'sm' : false}
        disableGutters={true}
      >
        {/* Begin of Sign Up Bonus */}
        {(isGuest || isNewUser) && elBonusBanner}
        {/* End of Sign Up Bonus */}
        {authMethod !== 'phoneNumber' && <PhoneSubmitDrawer />}
        {header && header.component}

        {isLoading ? (
          <Loader />
        ) : (
          <main
            style={{
              marginTop: renderMarginTop(),
              flexGrow: 1,
              minHeight: minHeight,
              paddingRight: '16px',
              paddingLeft: '16px',
              overflow: !isLandscape ? 'unset' : 'hidden',
              paddingBottom:
                !footer?.component || footer?.disablePadding
                  ? 'none'
                  : '72px',
            }}
          >
            {children}
          </main>
        )}
        {footer && footer.component}
      </StyledContainer>
    </>
  )
}

Layout.defaultProps = {
  header: null,
  footer: null,
  children: null,
  isLoading: false,
  isLandscape: false,
}

export default Layout
