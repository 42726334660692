import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLableVa,
  ItemsLableVaBold,
  VaDesc,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { getIdToken } from 'helpers/auth'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { useTranslation } from 'react-i18next'

interface ISuccessStateProps {
  vaStatus: {
    invoice_id: string
    payment_method_code: string
    status: string
    paid_at: string
    checkout_at: string
    payment_method_name: string
    total: number
  }
  itemType?: string
}

export default function SuccessStateContent({
  vaStatus,
  itemType,
}: ISuccessStateProps) {
  const { t } = useTranslation()
  const renderDescription = () => {
    if (itemType === 'event' && getIdToken()) {
      return t('key_thankyou_your_purchase_ticket_can_be_access')
    } else if (itemType === 'event' && !getIdToken()) {
      return t('key_thankyou_your_purchase_event')
    } else {
      return t('key_thankyou_your_purchase_content')
    }
  }
  return (
    <Box>
      <StateImage
        type="success-withdraw"
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <VaTitleLabel>
        {t('transactionVaStatusWidgetPaidTitle')}
      </VaTitleLabel>
      <VaDesc>{renderDescription()}</VaDesc>
      <WrapperItemsPending>
        <ItemsLableVa>
          {t('transactionVAStatusDetailPendingWidgetTotalLabel')}
          <ItemsLableVaBold>
            Rp{NumberBaseFormatter(vaStatus?.total)}
          </ItemsLableVaBold>
        </ItemsLableVa>
        <ItemsLableVa>
          {t('transactionVAStatusDetailPaidWidgetPaidAtLabel')}
          <ItemsLableVaBold>
            {dateFormatter({
              date: vaStatus?.paid_at,
              format: 'DD MMM YYYY, HH:mm',
            })}
          </ItemsLableVaBold>
        </ItemsLableVa>
        <ItemsLableVa>
          {t(
            'transactionVAStatusDetailPaidWidgetPaymentMethodNameLabel',
          )}
          <ItemsLableVaBold>BCA Virtual Account</ItemsLableVaBold>
        </ItemsLableVa>
      </WrapperItemsPending>
    </Box>
  )
}
