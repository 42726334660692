import { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
// styling
import { StyledDivider } from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import {
  StyledHelpIcon,
  StyledIconImg,
  StyledIconRadio,
  StyledInfoRadio,
  StyledWrapperInfo,
  TopUpButton,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
// component
import Box from 'components/commons/Box'
// lib
import { IProductsProps } from 'services/payment'
import theme from 'src/theme'
import { IPaymentMethod as IPaymentMethodV1 } from 'stores/domains/Wallet/models/TopUp/interface'
import { IPaymentMethod as IPaymentMethodV2 } from 'components/domains/Wallet/FeesPaymentDetail'
import { PaymentInfoBcaModal } from '../PaymentInfoBcaModal'
import { PaymentInfoCoinModal } from '../PaymentInfoCoinModal'

export interface ICartData {
  invoice_id?: string
  products: Array<IProductsProps>
  payment_method: Array<IPaymentMethodV1> | Array<IPaymentMethodV2>
}
interface IPaymentMethodRadio {
  paymentType: number
  cartValues: ICartData
  handlePaymentTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void
  topUpCoinHandler?: () => void
  t?: any
}
export default function PaymentMethodRadio({
  paymentType = 0,
  cartValues = null,
  handlePaymentTypeChange = () => {},
  topUpCoinHandler = () => {},
  t,
}: IPaymentMethodRadio) {
  const [openCoinInfo, setOpenCoinInfo] = useState(false)
  const [openBcaInfo, setOpenBcaInfo] = useState(false)

  return (
    <FormControl
      style={{
        width: '100%',
      }}
    >
      <RadioGroup
        value={paymentType}
        onChange={handlePaymentTypeChange}
      >
        <PaymentInfoCoinModal
          paymentItem={cartValues?.payment_method[paymentType]}
          openCoinInfo={openCoinInfo}
          setOpenCoinInfo={setOpenCoinInfo}
        />
        <PaymentInfoBcaModal
          paymentItem={cartValues?.payment_method[paymentType]}
          openBcaInfo={openBcaInfo}
          setOpenBcaInfo={setOpenBcaInfo}
        />
        {cartValues?.payment_method.map(
          (paymentItem, paymentItemIndex) => {
            return (
              <Box key={paymentItemIndex}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background:
                      paymentType === paymentItemIndex &&
                      theme.palette.background.tertiery,
                    borderRadius: '6px',
                    padding: '8px 8px',
                  }}
                >
                  <Box
                    style={{
                      display: 'block',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControlLabel
                      key={paymentItemIndex}
                      value={paymentItemIndex}
                      control={<Radio />}
                      label={
                        <StyledIconRadio>
                          <Box
                            sx={{
                              width: '20px',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginRight: '10px',
                            }}
                          >
                            <StyledIconImg
                              sx={{
                                width: '100%',
                                padding:
                                  paymentItem?.code === 'TIPTIP_COIN'
                                    ? '2px'
                                    : '0px !important',
                              }}
                              src={paymentItem.black_icon}
                            />
                          </Box>
                          {paymentItem.name}

                          {(paymentItem?.code === 'TIPTIP_COIN' ||
                            paymentItem?.code === 'H2H_BCA_VA') && (
                            <StyledHelpIcon
                              onClick={() => {
                                paymentItem?.code === 'TIPTIP_COIN'
                                  ? setOpenCoinInfo(true)
                                  : setOpenBcaInfo(true)
                              }}
                            />
                          )}
                        </StyledIconRadio>
                      }
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                    {paymentItem?.coin?.balance <
                      paymentItem?.coin?.total_price && (
                      <StyledWrapperInfo>
                        <Box sx={{ width: '58px' }} />
                        <StyledInfoRadio
                          sx={{
                            color: theme.palette.error.main,
                          }}
                        >
                          {t('paymentMethodV2WidgetCoinError', {
                            balance: paymentItem?.coin?.balance,
                          })}
                        </StyledInfoRadio>
                      </StyledWrapperInfo>
                    )}
                  </Box>
                  {paymentItem?.coin?.balance <
                    paymentItem?.coin?.total_price && (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginRight: 0,
                      }}
                    >
                      <TopUpButton
                        buttonVariant="filled"
                        onClick={topUpCoinHandler}
                      >
                        {t('topUpCoinButtonTitle')}
                      </TopUpButton>
                    </Box>
                  )}
                </Box>
                <StyledDivider />
              </Box>
            )
          },
        )}
      </RadioGroup>
    </FormControl>
  )
}
