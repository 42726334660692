import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material'

type IProps = {
  name: string
  options: {
    label: string
    value: any
  }[]
}

type Props = IProps & RadioGroupProps

export default function RHFRadioGroup({
  name,
  options,
  ...other
}: Props) {
  const { control } = useFormContext()

  const renderErrorText = (message: string | undefined) => {
    if (message) return <FormHelperText>{message}</FormHelperText>
  }

  return (
    <FormControl variant="standard" fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup
              {...field}
              row
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                color: theme.palette.text.primary,
              })}
              {...other}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{
                    width: '100%',
                  }}
                />
              ))}
            </RadioGroup>
            {renderErrorText(error?.message)}
          </>
        )}
      />
    </FormControl>
  )
}
