interface IOptions {
    style?: string
    currency?: string
    minimumFractionDigits: number
  }
  
  function CurrencyFormatter(
    num: number,
    locales = 'id-ID',
    options: IOptions = {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    },
  ): string {
    const numberFormatting = new Intl.NumberFormat(
      locales,
      options,
    ).format(num)
  
    if (locales === 'id-ID' && options?.currency === 'IDR') {
      return numberFormatting.replace(/\s+/g, '')
    }
  
    return numberFormatting
  }
  
  export default CurrencyFormatter
  