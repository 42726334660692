import isEmpty from 'helpers/is-empty'

const allowedImageExtension = ['jpg', 'jpeg', 'png']

function getURLExtension(url: string) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

export default function imageResizer(
  imageUrl: string,
  imageSize:
    | '100w'
    | '200w'
    | '300w'
    | '500w'
    | '700w'
    | '1200w' = '500w',
) {
  if (!isEmpty(imageUrl)) {
    if (
      !imageSize ||
      !(
        process.env.NEXT_PUBLIC_IMAGE_RESIZE &&
        process.env.NEXT_PUBLIC_IMAGE_RESIZE.toLowerCase() === 'true'
      ) ||
      allowedImageExtension.indexOf(getURLExtension(imageUrl)) < 0
    ) {
      return imageUrl
    }

    const newImageUrl = imageUrl.split('/')

    newImageUrl.splice(newImageUrl.length - 1, 0, imageSize)
    return newImageUrl.join('/')
  }
}
