import { styled, StackProps } from '@mui/material'
import Button from '../Button'
import Stack from '../Stack'
import Typography from '../Typography'

const StyledStack = styled(Stack)<{ withBackground?: boolean }>(
  ({ theme, withBackground }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      withBackground && theme.palette.background.primary,
  }),
)

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3Desk,
  color: theme.palette.text.primary,
  textAlign: 'center',
  letterSpacing: '0.042px',

  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h3,
    lineHeight: '22px',
    letterSpacing: '0.03px',
  },
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.mediumRegular,
  color: theme.palette.text.secondary,
  textAlign: 'center',
  letterSpacing: '0.024px',

  [theme.breakpoints.down('sm')]: {
    ...theme.typography.normalRegular,
    lineHeight: '21px',
    letterSpacing: '0.021px',
  },
}))

export interface ErrorPageProps extends StackProps {
  title: string
  subtitle: string
  illustration: React.ReactNode
  onRefresh: Function
  hideHomeButton?: boolean
}

function ErrorPage({
  title,
  subtitle,
  illustration,
  onRefresh,
  hideHomeButton,
  ...rest
}: ErrorPageProps) {
  return (
    <StyledStack width="100%" p={0} withBackground {...rest}>
      <StyledStack width="320px" spacing={2}>
        {illustration}
        <Stack spacing={1.5}>
          <Title as="span">{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Stack>
        <Stack direction="row" spacing={1}>
          {!hideHomeButton && (
            <Button buttonVariant="outlined" type="button" href="/">
              Kembali ke TipTip
            </Button>
          )}
          <Button
            buttonVariant="filled"
            type="button"
            onClick={() => onRefresh()}
          >
            Refresh
          </Button>
        </Stack>
      </StyledStack>
    </StyledStack>
  )
}

export default ErrorPage
