import { Grid, styled } from '@mui/material'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import Card from 'components/commons/Card'
import Typography from 'components/commons/Typography'

export const VoucherContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  margin: 'auto',
  paddingLeft: 14,
  paddingRight: 14,
}))

export const VoucherName = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.h3.fontWeight,
  fontSize: 22,
  lineHeight: '22px',
  letterSpacing: '0.0015em',
  float: 'left',
  '@media screen and (max-width:420px)': {
    fontSize: 20,
  },
  '@media screen and (max-width:375px)': {
    fontSize: 16,
  },
  '@media screen and (max-width:320px)': {
    fontSize: 14,
  },
}))

export const VoucherHead = styled(Grid)({
  marginBottom: 10,
  svg: {
    transform: 'scale(0.8)',
    float: 'right',
    marginTop: '-20px',
    marginRight: '-20px',
  },
  '@media screen and (max-width:500px)': {
    marginBottom: 10,
    marginTop: '10px',
    svg: {
      transform: 'scale(0.7)',
      marginBottom: '-50px !important',
      marginTop: '-15px',
      marginRight: '-20px',
    },
  },
  '@media screen and (max-width:420px)': {
    marginBottom: 15,
    svg: {
      transform: 'scale(0.6)',
      marginTop: '-18px',
      marginRight: '-40px',
    },
  },
  '@media screen and (max-width:375px)': {
    marginBottom: 15,
    svg: {
      transform: 'scale(0.5)',
      marginTop: '-17px',
    },
  },
  '@media screen and (max-width:300px)': {
    marginBottom: '5px !important',
    svg: {
      marginLeft: '-23px !important',
      marginBottom: '-50px !important',
    },
  },
})

export const VoucherDesc = styled(Box)(({ theme }) => ({
  clear: 'both',
  display: 'flex',
  letterSpacing: '0.0025em',
  lineHeight: '16px',
  fontWeight: theme.typography.smallRegular.fontWeight,
  fontSize: theme.typography.smallRegular.fontSize,
  marginBottom: 10,
  svg: {
    fontSize: 16,
  },
  '@media screen and (max-width:410px)': {
    marginBottom: 5,
    fontSize: '14px !important',
    svg: {
      fontSize: 14,
      marginTop: 2,
    },
  },
  '@media screen and (max-width:380px)': {
    fontSize: '10px !important',
    svg: {
      fontSize: 12,
    },
  },
  '@media screen and (max-width:300px)': {
    fontSize: '8px !important',
  },
}))
export const VoucherDescText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.smallRegular.fontWeight,
  fontSize: 16,
  lineHeight: '16px',
  '@media screen and (max-width:410px)': {
    fontSize: '12px !important',
  },
}))

export const VoucherButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.gradient1,
  padding: '12px 9px',
  maxWidth: '392px',
  maxHeight: '39px',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  marginTop: '16px !important',
  '@media screen and (max-width:650px)': {
    marginTop: '0px !important',
    padding: '8px 9px',
  },
  '@media screen and (max-width:375px)': {
    padding: '2px 4px',
    fontSize: 12,
  },
}))

export const VoucherCardWrapper = styled(Card)({
  maxWidth: 448,
  backgroundColor: '#fff0',
  cursor: 'pointer',
  marginBottom: 16,
  width: '100%',
  height: '100%',
  maxHeight: 211,
})

export const VoucherContentWrapper = styled(Box)({
  display: 'block',
  margin: 'auto',
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingTop: '30px',
  height: '100%',
  '@media screen and (max-width:570px)': {
    paddingTop: '20px',
  },
  '@media screen and (max-width:530px)': {
    paddingTop: '10px',
  },
  '@media screen and (max-width:500px)': {
    paddingTop: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  '@media screen and (max-width:479px)': {
    paddingTop: '20px',
  },
  '@media screen and (max-width:420px)': {
    paddingTop: '15px',
  },
  '@media screen and (max-width:350px)': {
    paddingTop: '0px',
  },
})
