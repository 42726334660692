import delay from 'lodash/delay'
import { useRouter } from 'next/router'
import { Avatar, StackProps, useTheme } from '@mui/material'

import MenuNavigation from 'components/commons/MenuNavigation'
import Stack from 'components/commons/Stack'

import { IProfileData } from 'stores/domains/User/UserProfile'
import trackEvent from 'src/trackers'
import { useEffect, useState } from 'react'

interface ProfileHeaderProps extends StackProps {
  profileData: IProfileData
}

const TIMEOUT_IN_MS = 500

function ProfileHeader({
  profileData,

  ...rest
}: ProfileHeaderProps) {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const theme = useTheme()
  const name = profileData.name

  const [isActive, setIsActive] = useState<boolean>(true)

  const setActiveDropdown = () => {
    delay(() => {
      if (isActive) return
      setIsActive(true)
    }, TIMEOUT_IN_MS)
  }

  const setInactiveDropdown = () => {
    delay(() => {
      if (!isActive) return
      setIsActive(false)
    }, TIMEOUT_IN_MS)
  }

  useEffect(() => {
    if (isActive) {
      trackEvent.user('click_profile_dropdown', trackerData)
    }
  }, [isActive])

  return (
    <Stack
      position="relative"
      sx={(theme) => ({
        display: 'none',
        ':hover .button': {
          color: theme.palette.text.primary,
        },
        ':hover .dropdown-content': {
          visibility: 'visible',
          opacity: 1,
        },
        [theme.breakpoints.up('xs')]: {
          display: 'inline-block',
        },
      })}
      {...rest}
    >
      <Stack
        spacing={1}
        pl={1}
        direction="row"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onMouseOver={setActiveDropdown}
      >
        <Avatar
          alt={name}
          src={profileData.avatar}
          sx={{
            width: 40,
            height: 40,
            background: theme.palette.info.main,
          }}
        />
      </Stack>
      <Stack
        className="dropdown-content"
        visibility="hidden"
        position="absolute"
        paddingY={2}
        zIndex={1}
        right={0}
        sx={{
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
        onMouseLeave={setInactiveDropdown}
      >
        <Stack
          id="c-common-profile-menu"
          sx={(theme) => ({
            padding: 3,
            color: theme.palette.text.primary,
            background: theme.palette.background.primary,
            boxShadow: `0px 8px 8px ${theme.palette.border.bold}`,
            borderRadius: '12px',
            height: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
          })}
        >
          <MenuNavigation />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ProfileHeader
