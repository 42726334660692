import authConfig from 'configs/auth'
import { destroyCookie } from 'helpers/cookie'
import store from 'stores/index'
import { resetUser } from 'helpers/analytics'
import { trackEvent } from 'helpers/analytics'
import { getDateTimeUTC } from 'helpers/date-time'
import { resetLogUser } from 'helpers/log'
import * as moe from 'helpers/moengage'
import { destroyLocalStorage } from 'helpers/local-storage'
import { deleteLastAuthUser } from 'helpers/cognito/token'
import { resetMessageStore } from 'stores/domains/User/Message'
import { setFcmToken } from 'helpers/firebase'
import { useNotificationStore } from 'stores/commons'
import { resetCommunityStore } from 'stores/domains/Social/Community'
import { resetUserProfileStore } from 'stores/domains/User/UserProfile'
import { resetMissionCategoryStore } from 'stores/domains/Social/Mission'
import { deleteCookiesQueueToken } from 'helpers/queue'

export const resetDataAfterLogout = (deleteQueueToken = true) => {
  useNotificationStore.persist.clearStorage()
  deleteLastAuthUser()
  destroyLocalStorage(authConfig.accessTokenName)
  destroyLocalStorage(authConfig.refreshTokenName)
  destroyCookie(authConfig.idTokenName, '/')
  if (deleteQueueToken) deleteCookiesQueueToken()
  destroyCookie('user_id', '/')
  destroyCookie('username', '/')
  destroyCookie('email', '/')
  destroyLocalStorage('userPhoneNumber')
  destroyLocalStorage('countryData')
  destroyLocalStorage('hidePhoneNumberDrawer')
  clearLocalStorageExcept('i18nextLng')

  moe.trackLogout()
  resetUserProfileStore()
  resetCommunityStore()
  resetMessageStore()
  resetMissionCategoryStore()
  store.persist.clear().then(() => {
    // eslint-disable-next-line no-console
    console.log('persisted state cleared')
  })
  resetLogUser()
  resetUser()
}

function clearLocalStorageExcept(keyToKeep) {
  const keysToRemove = []

  // Iterate over all items in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)

    // If the key or value doesn't match the one to keep, mark it for removal
    if (key !== keyToKeep) {
      keysToRemove.push(key)
    }
  }

  // Remove all keys that don't match the one to keep
  keysToRemove.forEach((key) => localStorage.removeItem(key))
}

/**
 * Handle after logout steps
 *
 * @param cb callback function, usually used to redirect to referer or login page
 */
const afterLogout = (
  // eslint-disable-next-line no-unused-vars
  cb: (params?: any) => void,
  isExcludeSession?: boolean,
  withoutCallFirebase: boolean = false,
  deleteQueueToken: boolean = true,
): Function => {
  trackEvent('sign_out', {
    state_name: 'ACCOUNT_SETTING',
    sign_out_datetime: getDateTimeUTC(new Date().toISOString()),
  })

  // Set FCM token Firebase to database
  if (!withoutCallFirebase) {
    setFcmToken('SIGN_OUT').then(() => {
      resetDataAfterLogout(deleteQueueToken)
      if (!isExcludeSession) {
        sessionStorage.clear()
      }

      const timeOut = setTimeout(() => {
        cb()
      }, 1000)
      return () => {
        clearTimeout(timeOut)
      }
    })
  } else {
    resetDataAfterLogout(deleteQueueToken)
    if (!isExcludeSession) {
      sessionStorage.clear()
    }

    const timeOut = setTimeout(() => {
      cb()
    }, 1000)
    return () => {
      clearTimeout(timeOut)
    }
  }
}
export default afterLogout
