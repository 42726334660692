import { Modal, StyledComponentProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  ActionButtonContainer,
  CloseModalButton,
  ContainerBtn,
  ModalContent,
  ModalTitle,
  StyledContainer,
  StyledSubmitButton,
} from './styled'
import CommonStack from 'components/commons/Stack'
import Button from 'components/commons/Button'

interface Props {
  id?: string
  title?: string
  cancelLabel?: string
  content?: React.ReactNode
  showModal?: boolean
  onCloseAction?: () => void
  onSubmitAction?: () => void
  onLeftBtnAction?: () => void
  submitLabel?: string
  isRequesting?: boolean
  fullWidth?: boolean
  hideButtonRequest?: boolean
  hideCancelButton?: true
  onCancelAction?: () => void
  hideHeader?: boolean
  LeftBtnLabel?: string
  leftBtnLoading?: boolean
  activeButton?: boolean
  hideCloseButton?: boolean
  submitButtonId?: string
  swapButtonPosition?: boolean
  closeButtonId?: string
  variant?: 'filled' | 'outlined' | 'nude' | 'ghost'
  //toggle styling payment variant
  isXenditIframe?: boolean
  isEventOrderDetail?: boolean
  isVoucher?: boolean
  //
  sx?: StyledComponentProps
}
//This code used on DirectPaymentModal related only
function PaymentModal({
  id = '',
  title = '',
  cancelLabel = '',
  content = '',
  showModal = false,
  onCloseAction = () => {},
  onSubmitAction = () => {},
  onLeftBtnAction = () => {},
  submitLabel = '',
  isRequesting = false,
  fullWidth = false,
  hideButtonRequest = false,
  hideCancelButton = true,
  onCancelAction = () => {},
  hideHeader = false,
  LeftBtnLabel = '',
  hideCloseButton = false,
  submitButtonId = '',
  swapButtonPosition = false,
  closeButtonId = '',
  variant = 'filled',
  //toggle styling payment variant
  isXenditIframe = false,
  isEventOrderDetail = false,
  isVoucher = false,
  //
  sx = {},
}: Props) {
  return (
    <Modal
      id={id}
      open={showModal}
      onClose={() => onCloseAction()}
      sx={{
        ...sx,
      }}
    >
      <StyledContainer
        //Related to all style in payment
        isXenditIframe={isXenditIframe}
        isEventOrderDetail={isEventOrderDetail}
        isVoucher={isVoucher}
      >
        <CommonStack sx={{ height: '100%' }}>
          {!hideHeader && (
            <CommonStack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ModalTitle>{title}</ModalTitle>
              {!hideCloseButton && (
                <CloseModalButton
                  buttonVariant="nude"
                  onClick={() => onCloseAction()}
                  sx={{
                    textTransform: 'unset',
                  }}
                  id={closeButtonId !== '' && closeButtonId}
                >
                  {cancelLabel && cancelLabel !== '' ? (
                    cancelLabel
                  ) : (
                    <CloseIcon />
                  )}
                </CloseModalButton>
              )}
            </CommonStack>
          )}
          <ModalContent>{content}</ModalContent>
          <ActionButtonContainer>
            {!hideButtonRequest && (
              <ContainerBtn
                direction="row"
                justifyContent={fullWidth ? 'center' : 'flex-start'}
                alignItems="center"
                spacing={2}
                style={
                  swapButtonPosition
                    ? {
                        display: 'flex',
                        flexDirection: 'row-reverse',
                      }
                    : {}
                }
              >
                {LeftBtnLabel !== '' && (
                  <Button
                    buttonVariant="outlined"
                    disableElevation
                    fullWidth
                    onClick={onLeftBtnAction}
                    disabled={isRequesting}
                    sx={{
                      textTransform: 'unset',
                      height: '44px',
                    }}
                  >
                    {LeftBtnLabel}
                  </Button>
                )}
                <Button
                  buttonVariant={variant}
                  disableElevation
                  fullWidth
                  onClick={onSubmitAction}
                  disabled={isRequesting}
                  sx={{
                    textTransform: 'unset',
                    height: '44px',
                  }}
                  id={submitButtonId !== '' && submitButtonId}
                  style={
                    swapButtonPosition
                      ? { marginLeft: 0, marginRight: 16 }
                      : {}
                  }
                >
                  {submitLabel}
                </Button>
              </ContainerBtn>
            )}
            {!hideCancelButton && (
              <ContainerBtn
                direction="row"
                justifyContent={fullWidth ? 'center' : 'flex-start'}
                alignItems="center"
                spacing={2}
              >
                <StyledSubmitButton
                  background="transparent"
                  variant="outlined"
                  disableElevation
                  fullWidth
                  onClick={onCancelAction}
                  disabled={isRequesting}
                  sx={{
                    textTransform: 'unset',
                  }}
                  style={{ marginTop: 12 }}
                >
                  {cancelLabel}
                </StyledSubmitButton>
              </ContainerBtn>
            )}
          </ActionButtonContainer>
        </CommonStack>
      </StyledContainer>
    </Modal>
  )
}

export default PaymentModal
