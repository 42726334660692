import { Divider, styled } from '@mui/material'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import ErrorIcon from 'assets/svg/session/session-unload.svg'

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: 16,
  marginBottom: 16,
  background: theme.palette.background.tertiery,
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
}))

export const MainTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.mediumBold,
  color: theme.palette.text.primary,
  letterSpacing: '0.0015em',
  marginBottom: '8px',
  ul: { marginBlockStart: '0px !important', paddingInlineStart: 20 },
  li: {
    ...theme.typography.normalRegular,
    color: theme.palette.text.secondary,
    letterSpacing: '0.0015em',
    marginBottom: '8px',
  },
}))
export const SubTypography = styled('ul')(({ theme }) => ({
  ...theme.typography.normalRegular,
  color: theme.palette.text.secondary,
  letterSpacing: '0.0015em',
  marginBottom: '8px',
  marginBlockStart: '0px !important',
  paddingInlineStart: 20,
}))
export const ErrorDesc = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.normalRegular,
  textAlign: 'center',
  marginBottom: 16,
}))
export const StyledErrorIcon = styled(ErrorIcon)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '55px',
})
export const ErrorLabel = styled(Stack)(({ theme }) => ({
  ...theme.typography.h3,
  lineHeight: theme.typography.mediumRegular.lineHeight,
  textAlign: 'center',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
  marginBottom: 8,
}))
