import { ICustomField } from '../interface'
import ImageUploading, {
  ErrorsType,
  ImageListType,
  ImageType,
} from 'react-images-uploading'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import isEmpty from 'helpers/is-empty'
import ErrorIcon from '@mui/icons-material/Error'
import {
  FieldSubtitle,
  StyledIconContainer,
  StyledImagePlaceholder,
  UploadButton,
} from '../styled'
import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { makeXMLRequest } from 'helpers/make-xml-request'

import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import LinearProgressWithIcon from 'components/commons/LinearProgressWithIcon'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import FileIcon from 'assets/svg/ic_file.svg'

interface IUploadFileProps
  extends ICustomField,
    UseFormReturn<any, any> {
  disabled: boolean
  onGetImageList?: Function
  defaultImages?: ImageListType
}

export default function CustomFileUpload({
  clearErrors,
  setError,
  formState,
  name,
  validation_error_message,
  validation,
  label,
  file_upload_url,
  defaultImages = null,
  ...props
}: IUploadFileProps) {
  const [images, setImages] = useState<ImageListType>()
  const [imageError, setImageError] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [request, setRequest] = useState<XMLHttpRequest>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const { errors } = formState

  const handleRemoveUploadedImage = () => {
    resetError()
    setIsUploading(false)
    setProgress(0)
  }

  const handleErrorUploadImage = () => {
    setImageError(true)
    setError(name, {
      message: 'File gagal diupload, silakan coba lagi',
      type: 'pattern',
    })
  }

  const uploadImage = async (image: ImageType) => {
    try {
      setIsUploading(true)
      const filename = image?.file?.name
      const responseFile = await apiClient({
        url: file_upload_url,
        method: 'POST',
        token: getIdToken(),
        data: {
          ext:
            '.' +
            filename
              .substring(filename.lastIndexOf('.') + 1)
              .toLowerCase(),
        },
      })
      if (responseFile?.data?.code === 'SUCCESS') {
        const blob = await (await fetch(image.dataURL)).blob()
        await makeXMLRequest({
          contentType: image.file.type,
          url: responseFile.data.data.presign_url,
          blob,
          handleUploadProgress: setProgress,
          setRequest: setRequest,
          handleError: handleErrorUploadImage,
        })
        return responseFile.data.data.file_name
      }
    } catch (error) {
      handleErrorUploadImage()
    } finally {
      setIsUploading(false)
    }
  }

  const handleUploadImage = async (imageList: ImageListType) => {
    let images = []
    let newImageList: Array<any> = [...imageList]
    for (let i = 0; i < imageList.length; i++) {
      const fileUploaded = await uploadImage(imageList[i])
      images.push(fileUploaded)
      newImageList[i].file = {
        name: imageList[i].file.name,
        type: imageList[i].file.type,
        value: fileUploaded,
      }
    }
    props.onValueChange(images)
    if (props?.onGetImageList) props.onGetImageList(newImageList)
  }

  const resetError = () => {
    setImageError(false)
    clearErrors(name)
  }

  const handleChange = (imageList: ImageListType) => {
    setImageError(false)
    resetError()
    if (imageList) {
      setImages(imageList)
      handleUploadImage(imageList)
    }
  }

  const handleError = (errors: ErrorsType) => {
    if (!isEmpty(errors)) {
      setImageError(true)
      let message = ''
      if (errors?.maxFileSize) {
        message =
          validation_error_message?.max_file_size ||
          `Ukuran file ${label} maksimum ${
            validation.max_file_size / 1000
          } kb`
      }

      if (errors?.acceptType) {
        message =
          validation_error_message?.allowed_file_extensions ||
          `Tipe file ${label} yang dapat diupload adalah ${validation.allowed_file_extensions.join(
            ', ',
          )}`
      }
      setError(name, {
        message,
        type: 'pattern',
      })
    }
  }

  useEffect(() => {
    setImages(defaultImages)
  }, [defaultImages])

  return (
    <ImageUploading
      acceptType={validation.allowed_file_extensions}
      value={images}
      onChange={handleChange}
      onError={(errors) => {
        handleError(errors)
      }}
      maxFileSize={validation.max_file_size * 1000}
      allowNonImageType={validation.allowed_file_extensions?.includes(
        'pdf',
      )}
      multiple
      maxNumber={1}
    >
      {({ imageList, onImageUpload, onImageRemove }) => (
        <Stack spacing={1} sx={{ marginTop: '8px !important' }}>
          {!imageError &&
            imageList.length > 0 &&
            imageList.map((image, index) =>
              isUploading ? (
                <LinearProgressWithIcon
                  value={progress}
                  onCancel={() => {
                    request.abort()
                    setIsUploading(false)
                    onImageRemove(index)
                  }}
                />
              ) : (
                <Box
                  key={index}
                  flexDirection={'row'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Stack
                    direction={'row'}
                    spacing={1}
                    alignItems={'center'}
                  >
                    {image.file.type.includes('pdf') ? (
                      <StyledIconContainer>
                        <FileIcon />
                      </StyledIconContainer>
                    ) : (
                      <StyledImagePlaceholder
                        src={image?.dataURL}
                        alt="uploaded-image"
                      />
                    )}
                    <FieldSubtitle content={image?.file.name} />
                  </Stack>
                  <CloseRoundedIcon
                    className="b-social-delete-uploaded-answer-file"
                    sx={(theme) => ({
                      color: theme.palette.text.tertiery,
                      fontSize: '16px',
                      cursor: 'pointer',
                    })}
                    onClick={() => {
                      onImageRemove(index)
                      handleRemoveUploadedImage()
                    }}
                  />
                </Box>
              ),
            )}
          {imageList.length === 0 && (
            <UploadButton
              onClick={() => {
                if (props.disabled) return
                onImageUpload()
              }}
              size="small"
              buttonVariant="outlined"
            >
              {props.placeholder}
            </UploadButton>
          )}
          {imageError && !isEmpty(errors?.[`${name}`]?.message) && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={(theme) => ({
                color: theme.palette.error.main,
                marginLeft: 0,
                '-webkit-appearance': 'none',
                fontSize: '12px',
              })}
            >
              <ErrorIcon
                sx={{ fontSize: 'inherit', marginRight: '4px' }}
              />
              {errors[`${name}`].message}
            </Stack>
          )}
        </Stack>
      )}
    </ImageUploading>
  )
}
