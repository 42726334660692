import { SxProps, useMediaQuery, useTheme } from '@mui/material'

import BottomDrawer from 'components/commons/BottomDrawer'
import ModalDialog from 'components/commons/Modal/Dialog/v2'

import CloseIcon from '@mui/icons-material/Close'
import isEmpty from 'helpers/is-empty'

interface ModalDrawerProps {
  title: string
  children: React.ReactNode
  onClose: Function
  isOpen?: boolean
  isLoading?: boolean
  closeButtonId?: string
  submitButtonId?: string
  sxDialogContainer?: SxProps
  sxDrawerContainer?: SxProps
  hideCloseButton?: boolean
}

export default function ModalDrawer({
  children,
  title,
  isOpen = false,
  isLoading = false,
  onClose,
  closeButtonId,
  submitButtonId,
  sxDialogContainer,
  sxDrawerContainer,
  hideCloseButton,
}: ModalDrawerProps) {
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )

  return (
    <>
      {isDesktopResolution ? (
        <ModalDialog
          title={title}
          open={isOpen}
          onClose={onClose}
          closeButtonIcon={!hideCloseButton && <CloseIcon />}
          sx={sxDialogContainer}
          isNoCloseButton={hideCloseButton}
          renderTitle={!isEmpty(title)}
        >
          {children}
        </ModalDialog>
      ) : (
        <BottomDrawer
          title={title}
          content={children}
          hideCloseButton={hideCloseButton}
          showDrawer={isOpen}
          onCloseAction={onClose}
          isRequesting={isLoading}
          closeButtonId={closeButtonId}
          submitButtonId={submitButtonId}
          hideButtonRequest
          hideCancelButton
          disablePadding
          sx={sxDrawerContainer}
        />
      )}
    </>
  )
}
