const whitelistedEvents = [
  'visit_home',
  'visit_event_detail',
  'purchase_eticket_completed',
  'visit_eticket_option',
  'visit_eticket_additional_form',
  'confirm_eticket_additional_form',
  'sign_up_completed',
  'sign_in_completed',
  'visit_creator_profile',
]
export default whitelistedEvents
