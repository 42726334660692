import { useState } from 'react'

import Box from 'components/commons/Box'
import PaymentModal from 'components/commons/PaymentModal/v2'
import MaintenanceBody from 'components/domains/Home/MaintenanceBody'
import BottomDrawer from 'components/commons/BottomDrawer'
import StateImage from 'components/commons/StateImage'
import ErrorResponseCode from 'components/commons/ErrorResponseCode'

import {
  ErrorDesc,
  ErrorLabel,
  EventAdjacentSeatErrorIcon,
  EventSelectedSeatErrorIcon,
  StyledUnhappyBookingIcon,
  StyledWaitingMoneyIcon,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import redirect from 'helpers/redirect'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import appConfig from 'configs/app'
import MaxCapacityBody from 'components/domains/Home/MaxCapacityBody'
import LimitExceedBody from 'components/domains/Home/LimitExceedBody'
import { useTranslation } from 'react-i18next'

interface IErrorPayment {
  itemType: string
  onClose: Function
  retryPaymentModal?: Function
  setSelectedPaymentType?: Function
  errorMessageCheckout?: String
  setCheckoutValues?: Function
  isErrorCart?: boolean | any
  setIsErrorCart?: Function
  isErrorCheckout?: boolean | any
  setIsErrorCheckout?: Function
  setIsErrorVaInstruction?: Function
  isLoadingCheckout?: boolean
  isLoadingCart?: boolean
  isErrorVaInstruction?: boolean | any
  isLoadingVaInstruction?: boolean
  setShowTopUpModal?: Function
  continueTransaction?: Function
  cancelTransaction?: Function
  errorVaStatus?: boolean | any
  setShowPaymentMethod?: Function
}

export default function ErrorPayment({
  itemType,
  onClose,
  retryPaymentModal = () => {},
  setSelectedPaymentType = () => {},
  errorMessageCheckout,
  setCheckoutValues = () => {},
  isErrorCart,
  setIsErrorCart = () => {},
  isErrorCheckout,
  setIsErrorCheckout = () => {},
  setIsErrorVaInstruction = () => {},
  isErrorVaInstruction,
  isLoadingVaInstruction,
  isLoadingCart,
  isLoadingCheckout,
  setShowTopUpModal,
  continueTransaction,
  cancelTransaction,
  errorVaStatus,
  setShowPaymentMethod,
}: IErrorPayment) {
  const { t } = useTranslation()

  const [isLeftBtnLoading, setIsLeftBtnLoading] = useState(false)
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )

  const isTicketSoldout =
    isErrorCheckout?.response?.data?.code === 'INSUFFICIENT_QUOTA'
  const isTicketFullyBooked =
    isErrorCheckout?.response?.data?.code === 'NOT_ENOUGH_QUOTA'
  const isUserOverbooked =
    isErrorCheckout?.response?.data?.code === 'USER_OVERBOOKED'
  const isSessionSoldout =
    isErrorCheckout?.response?.data?.code === 'SESSION_FULLY_BOOKED'
  const isSessionFullyBooked =
    isErrorCart?.response?.data?.code === 'SESSION_FULLY_BOOKED'
  const isNotSalePeriod =
    isErrorCart?.response?.data?.code === 'SALE_NOT_STARTED' ||
    isErrorCheckout?.response?.data?.code === 'SALE_NOT_STARTED'
  const isInvalidSalesPeriod =
    isErrorCart?.response?.data?.code === 'INVALID_SALES_PERIOD' ||
    isErrorCheckout?.response?.data?.code === 'INVALID_SALES_PERIOD'
  const isProductUnavailable =
    isErrorCart?.response?.data?.code === 'PRODUCT_UNAVAILABLE'
  const isIdNumberAlreadyUsed =
    isErrorCart?.response?.data?.code === 'ID_NUMBER_ALREADY_USED' ||
    isErrorCheckout?.response?.data?.code === 'ID_NUMBER_ALREADY_USED'

  const errorTitleHandler = () => {
    switch (isErrorCheckout?.response?.data?.code) {
      case 'USER_OVERBOOKED':
        return t('eventUserOverbookedTitle')
      case 'NOT_ENOUGH_QUOTA':
        return t('eventDetailsErrorQuantityExceedsQuotaTitle')
      case 'INVALID_ALREADY_BOOK':
        return t('eventSeatSelectionErrorSeatConflictedTitle')
      case 'SEAT_UNAVAILABLE':
        return t('eventSeatSelectionErrorSeatUnavailable')
      case 'INVALID_SEAT_RULES':
        return t('eventSeatSelectionErrorSeatRuleTitle')
      case 'GUEST_PENDING_TRANSACTION':
        return t('key_previous_order_not_paid')
      case 'PRODUCT_UNAVAILABLE':
        return t('eventUnpublishedTitle')
      default:
        return t('otherApiErrorTitle')
    }
  }

  const errorDescriptionHandler = () => {
    switch (isErrorCheckout?.response?.data?.code) {
      case 'USER_OVERBOOKED':
        return t('eventUserOverbookedDescription')
      case 'NOT_ENOUGH_QUOTA':
        return t('eventDetailsQuantityExceedsQuotaSubtitle')
      case 'INVALID_ALREADY_BOOK':
        return t('eventSeatSelectionErrorSeatConflictedDescription')
      case 'INVALID_SEAT_RULES':
        return t('eventSeatSelectionErrorSeatRuleDescription')
      case 'SEAT_UNAVAILABLE':
        return t('eventSeatSelectionErrorSeatUnavailableDesc')
      case 'GUEST_PENDING_TRANSACTION':
        return (
          isErrorCheckout?.response?.data?.data?.body ||
          t('vaPaymentInstructionWidgetErrorDesc')
        )
      case 'PRODUCT_UNAVAILABLE':
        return t('eventUnpublishedDescription')
      default:
        return t('vaPaymentInstructionWidgetErrorDesc')
    }
  }

  const ErrorIconRender = () => {
    switch (isErrorCheckout?.response?.data?.code) {
      case 'USER_OVERBOOKED':
      case 'NOT_ENOUGH_QUOTA':
        return (
          <StateImage
            type="ticket-not-available"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        )
      case 'INVALID_ALREADY_BOOK':
        return <EventSelectedSeatErrorIcon />
      case 'SEAT_UNAVAILABLE':
      case 'INVALID_SEAT_RULES':
        return <EventAdjacentSeatErrorIcon />
      case 'GUEST_PENDING_TRANSACTION':
        return <StyledWaitingMoneyIcon />
      default:
        return (
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        )
    }
  }

  const SessionFullyBookedContent = () => {
    return (
      <Box>
        <StyledUnhappyBookingIcon />
        <ErrorLabel>{t('sessionFullyBookedWidgetTitle')}</ErrorLabel>
        <ErrorDesc>
          {t('purchaseTicketBottomSheetSessionFullyBookedSubtitle')}
        </ErrorDesc>
      </Box>
    )
  }

  const ErrorRender = (
    isTicketSoldout,
    isSessionFullyBooked,
    isSessionSoldout,
    isUserOverbooked,
    isTicketFullyBooked,
    isNotSalePeriod,
    isProductUnavailable,
  ) => {
    if (
      isErrorCart?.response?.status === 503 ||
      isErrorCheckout?.response?.status === 503 ||
      isErrorVaInstruction?.response?.status === 503
    ) {
      return <MaintenanceBody isButtonHide />
    } else if (
      isErrorCart?.response?.status === 429 ||
      isErrorCheckout?.response?.status === 429 ||
      isErrorVaInstruction?.response?.status === 429
    ) {
      if (
        isErrorCheckout?.response?.data?.code ===
          'MAX_CAPACITY_EXCEEDED' ||
        isErrorCart?.response?.data?.code ===
          'MAX_CAPACITY_EXCEEDED' ||
        isErrorVaInstruction?.response?.data?.code ===
          'MAX_CAPACITY_EXCEEDED'
      ) {
        return <MaxCapacityBody isButtonHide />
      } else if (
        isErrorCheckout?.response?.data?.code ===
          'RATE_LIMIT_EXCEEDED' ||
        isErrorCart?.response?.data?.code === 'RATE_LIMIT_EXCEEDED' ||
        isErrorVaInstruction?.response?.data?.code ===
          'RATE_LIMIT_EXCEEDED'
      ) {
        return <LimitExceedBody isButtonHide />
      }
    } else if (
      isTicketSoldout ||
      isSessionFullyBooked ||
      isSessionSoldout
    ) {
      if (itemType === 'session') {
        return <SessionFullyBookedContent />
      } else {
        return (
          <ErrorResponseCode
            code={'INSUFFICIENT_QUOTA'}
            showCTA
            onCTA={(cta) => {
              switch (cta) {
                case 'browse_events':
                  redirect('/browse?status=event')
                case 'back_to_event':
                  window.location.reload()
              }
            }}
            sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
            t={t}
          />
        )
      }
    } else if (isUserOverbooked) {
      return (
        <Box>
          <StateImage
            type="ticket-not-available"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>{t('eventUserOverbookedTitle')}</ErrorLabel>
          <ErrorDesc>
            Kamu tidak bisa melanjutkan proses karena telah melebihi
            kuota pembelian tiket. Pastikan tidak ada pembayan
            tertunda di transaksi kamu.
          </ErrorDesc>
        </Box>
      )
    } else if (isTicketFullyBooked) {
      return (
        <Box>
          <StateImage
            type="ticket-not-available"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>
            {t('eventDetailsErrorQuantityExceedsQuotaTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsQuantityExceedsQuotaSubtitle')}
          </ErrorDesc>
        </Box>
      )
    } else if (isErrorCheckout?.response?.status === 500) {
      return (
        <Box
          style={{
            margin: 'auto',
            display: 'block',
            paddingTop: '5%',
          }}
        >
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>
            {t('paymentWidgetErrorWithRetryTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('paymentWidgetErrorWithRetryDesc')}
          </ErrorDesc>
        </Box>
      )
    } else if (isErrorCheckout?.response?.status === 406) {
      return (
        <Box
          style={{
            margin: 'auto',
            display: 'block',
            paddingTop: '5%',
          }}
        >
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>
            {t('paymentWidgetInsufficientCoinBalanceTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('paymentWidgetInsufficientCoinBalanceDesc')}
          </ErrorDesc>
        </Box>
      )
    } else if (isNotSalePeriod) {
      return (
        <Box>
          <StateImage
            type="date-time"
            sx={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '16px',
            }}
          />
          <ErrorLabel>
            {t('eventDetailsErrorSaleNotStartedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsErrorSaleNotStartedSubtitle')}.
          </ErrorDesc>
        </Box>
      )
    } else if (isInvalidSalesPeriod) {
      return <ErrorResponseCode code="INVALID_SALES_PERIOD" t={t} />
    } else if (isProductUnavailable) {
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={`${appConfig.assetBaseUrl}/webp/state_calendar.webp`}
          />
          <ErrorLabel>{t('eventUnpublishedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUnpublishedDescription')}</ErrorDesc>
        </Box>
      )
    } else if (
      isErrorCheckout?.response?.status === 400 &&
      itemType === 'coin'
    ) {
      return (
        <Box
          style={{
            margin: 'auto',
            display: 'block',
            paddingTop: '5%',
          }}
        >
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>Top-up Gagal</ErrorLabel>
          <ErrorDesc sx={{ textTransform: 'capitalize' }}>
            {errorMessageCheckout}
          </ErrorDesc>
        </Box>
      )
    } else if (errorVaStatus?.response?.status === 404) {
      return (
        <Box>
          <StateImage
            type="no-respond"
            sx={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '16px',
            }}
          />
          <ErrorLabel>
            {t('vaPaymentInstructionWidgetCanceledTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('vaPaymentInstructionWidgetCanceledDesc')}
          </ErrorDesc>
        </Box>
      )
    } else if (isIdNumberAlreadyUsed) {
      return (
        <ErrorResponseCode
          code="ID_NUMBER_ALREADY_USED"
          data={isErrorCheckout?.response?.data.data}
          showCTA
          onCTA={(cta) => {
            switch (cta) {
              case 'contact_cs':
                window.open(
                  `${appConfig.supportCenterUrl}home`,
                  '_blank',
                )
              case 'close':
                onClosePaymentHandler()
            }
          }}
        />
      )
    } else {
      return (
        <Box>
          {ErrorIconRender()}
          <ErrorLabel>{errorTitleHandler()}</ErrorLabel>
          <ErrorDesc>{errorDescriptionHandler()}</ErrorDesc>
        </Box>
      )
    }
  }

  const onCloseHandler = () => {
    onClose(
      isErrorCheckout?.response?.data?.code ||
        isErrorCart?.response?.data?.code ||
        errorVaStatus?.response?.data?.code,
    )
    setIsErrorCart(false)
    setIsErrorCheckout(false)
  }

  const onRetryHandler = () => {
    setCheckoutValues(null)
    setIsErrorCart(false)
    setIsErrorCheckout(false)
    retryPaymentModal(true)
  }

  const onContinueTransaction = () => {
    setIsErrorCart(false)
    setIsErrorCheckout(false)
    continueTransaction(isErrorCheckout?.response?.data?.data)
    setCheckoutValues({
      invoice_url: isErrorCheckout?.response?.data?.data?.invoice_url,
    })
  }

  const onClosePaymentHandler = () => {
    onCloseHandler()
    setIsErrorVaInstruction(false)
    setSelectedPaymentType(0)
  }

  //For General Error Submit
  const onSubmitErrorGeneralHandler = () => {
    const errorSource =
      isErrorCheckout?.response?.data?.code ||
      isErrorCart?.response?.data?.code ||
      errorVaStatus?.response?.data?.code
    switch (errorSource) {
      case 'SESSION_FULLY_BOOKED':
      case 'NOT_ENOUGH_QUOTA':
      case 'NOT_FOUND':
        onClosePaymentHandler()
        window.location.reload()
        break
      case 'INSUFFICIENT_BALANCE':
        setShowTopUpModal()
        onRetryHandler()
        break
      case 'NOT_ACCEPTABLE':
        onRetryHandler()
        break
      case 'GUEST_PENDING_TRANSACTION':
        onContinueTransaction()
        break
      case 'SEAT_UNAVAILABLE':
      case 'USER_OVERBOOKED':
      case 'INVALID_ALREADY_BOOK':
      case 'INVALID_SEAT_RULES':
      case 'MAX_CAPACITY_EXCEEDED':
      case 'RATE_LIMIT_EXCEEDED':
      default:
        isErrorCheckout?.response?.status === 400 &&
        itemType === 'coin'
          ? setShowPaymentMethod()
          : onClosePaymentHandler()
        break
    }
  }

  const onCloseErrorGeneralHandler = () => {
    if (isTicketSoldout) {
      onClosePaymentHandler()
      return redirect('/')
    } else if (isErrorCheckout?.response?.status === 406) {
      onRetryHandler()
    } else {
      onClosePaymentHandler()
      if (errorVaStatus?.response?.status) {
        window.location.reload()
      }
    }
  }
  const submitLabelHandler = () => {
    const errorSource =
      isErrorCheckout?.response?.data?.code ||
      isErrorCart?.response?.data?.code ||
      errorVaStatus?.response?.data?.code
    switch (errorSource) {
      case 'SESSION_FULLY_BOOKED':
      case 'NOT_ENOUGH_QUOTA':
        return t('refresh')
      case 'USER_OVERBOOKED':
        return t('close')
      case 'INVALID_ALREADY_BOOK':
        return t('eventTicketSelectionScreenTitle')
      case 'SEAT_UNAVAILABLE':
      case 'INVALID_SEAT_RULES':
        return t('reselect')
      case 'INSUFFICIENT_BALANCE':
        return t('topUpCoinButtonTitle')
      case 'NOT_ACCEPTABLE':
      case 'NOT_FOUND':
      case 'MAX_CAPACITY_EXCEEDED':
      case 'RATE_LIMIT_EXCEEDED':
        return t('back')
      case 'GUEST_PENDING_TRANSACTION':
        return t('guestPurchasePendingPaymentCompletePaymentButton')
      case 'PRODUCT_UNAVAILABLE':
        return t('eventUnpublishedButtonLabel')
      default:
        return isErrorCheckout?.response?.status === 400 &&
          itemType === 'coin'
          ? t('back')
          : t('close')
    }
  }

  const leftBtnLabelHandler = () => {
    const errorSource =
      isErrorCheckout?.response?.data?.code ||
      isErrorCart?.response?.data?.code

    switch (isErrorCheckout?.response?.status) {
      case 406:
        return t('back')
      case 400:
        if (errorSource === 'GUEST_PENDING_TRANSACTION')
          return t('transactionHistoryScreenCancelBtn')
        return ''
      default:
        return ''
    }
  }

  const leftBtnActionHandler = async () => {
    const errorSource =
      isErrorCheckout?.response?.data?.code ||
      isErrorCart?.response?.data?.code
    switch (errorSource) {
      case 'GUEST_PENDING_TRANSACTION':
        setIsLeftBtnLoading(true)
        await cancelTransaction(
          isErrorCheckout?.response?.data?.data.invoice_id,
        )
        setIsLeftBtnLoading(false)
        break
      default:
        onRetryHandler()
        break
    }
  }

  if (isDesktopResolution)
    return (
      <PaymentModal
        showModal={
          (isErrorCheckout && !isLoadingCheckout) ||
          (isErrorCart && !isLoadingCart) ||
          (isErrorVaInstruction && !isLoadingVaInstruction) ||
          errorVaStatus
        }
        onCloseAction={onCloseErrorGeneralHandler}
        variant="filled"
        hideButtonRequest={
          isLoadingCart ||
          isLoadingCheckout ||
          isTicketSoldout ||
          isIdNumberAlreadyUsed
        }
        submitLabel={submitLabelHandler()}
        onSubmitAction={onSubmitErrorGeneralHandler}
        title={
          (isSessionFullyBooked || isSessionSoldout) &&
          itemType === 'session' &&
          t('purchaseTicketBottomSheetTitle')
        }
        LeftBtnLabel={leftBtnLabelHandler()}
        activeButton
        onLeftBtnAction={leftBtnActionHandler}
        leftBtnLoading={isLeftBtnLoading}
        content={ErrorRender(
          isTicketSoldout,
          isSessionFullyBooked,
          isSessionSoldout,
          isUserOverbooked,
          isTicketFullyBooked,
          isNotSalePeriod,
          isProductUnavailable,
        )}
      />
    )
  else {
    return (
      <BottomDrawer
        showDrawer={
          (isErrorCheckout && !isLoadingCheckout) ||
          (isErrorCart && !isLoadingCart) ||
          (isErrorVaInstruction && !isLoadingVaInstruction)
        }
        onCloseAction={onCloseErrorGeneralHandler}
        variant="contained"
        hideButtonRequest={
          isLoadingCart ||
          isLoadingCheckout ||
          isTicketSoldout ||
          isIdNumberAlreadyUsed
        }
        submitLabel={submitLabelHandler()}
        onSubmitAction={onSubmitErrorGeneralHandler}
        title={
          (isSessionFullyBooked || isSessionSoldout) &&
          itemType === 'session' &&
          'Book Sesi'
        }
        LeftBtnLabel={leftBtnLabelHandler()}
        leftBtnLoading={isLeftBtnLoading}
        activeButton
        onLeftBtnAction={leftBtnActionHandler}
        content={ErrorRender(
          isTicketSoldout,
          isSessionFullyBooked,
          isSessionSoldout,
          isUserOverbooked,
          isTicketFullyBooked,
          isNotSalePeriod,
          isProductUnavailable,
        )}
      />
    )
  }
}
