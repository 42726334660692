import { action, thunk } from 'easy-peasy'
import { getCookie, isGuestMode } from 'helpers/cookie'
import get from 'lodash/get'
import authConfig from 'configs/auth'
import IJoinSessionModel from 'stores/commons/models/JoinSession/interface'
import { getDistinctID } from 'helpers/analytics'
import { setChannelInfo } from 'helpers/session-storage'

export const DEFAULT_CONFIG = {
  uid: null,
  token_rtc: '',
  token_rtm: '',
  share_screen_token: '',
  channelName: '',
  user_id: '',
  username: '',
  remaining_time: 0,
  extended_elapsed_time: 0,
}

const JoinSession: IJoinSessionModel = {
  // states
  isLoading: false,
  isError: false,
  isJoined: false,
  isJoinedLate: false,
  isJoinedEarly: false,
  isSesionEnd: false,
  isParticipantNotBooking: false,
  errorMessage: '',
  data: DEFAULT_CONFIG,
  // actions
  setParticipantNotBooking: action((state, payload) => {
    state.isParticipantNotBooking = payload
  }),
  setJoined: action((state, payload) => {
    state.isJoined = payload
  }),
  setJoinedLate: action((state, payload) => {
    state.isJoinedLate = payload
  }),
  setJoinedEarly: action((state, payload) => {
    state.isJoinedEarly = payload
  }),
  setSessionEnd: action((state, payload) => {
    state.isSesionEnd = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  // thunks
  onJoinSession: thunk(async (actions, sessionID, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      let response = undefined
      if (!isGuestMode()) {
        response = await apiClient({
          url: `/session/v1/live/${sessionID}/join`,
          method: 'POST',
          token: getCookie(authConfig.idTokenName),
        })
      } else {
        response = await apiClient({
          url: `/session/guest/v1/live/${sessionID}/join`,
          method: 'POST',
          headers: {
            'Distinct-Id': getDistinctID(),
          },
        })
      }
      setChannelInfo(sessionID, {
        rtmToken: get(response, 'data.data.rtm_token', ''),
        rtcToken: get(response, 'data.data.token', ''),
        username: get(response, 'data.data.user.username', ''),
      })
      actions.setData({
        token_rtc: get(response, 'data.data.token', ''),
        token_rtm: get(response, 'data.data.rtm_token', ''),
        share_screen_token: get(
          response,
          'data.data.share_screen_token',
          '',
        ),
        channelName: get(response, 'data.data.session_id', ''),
        uid: get(response, 'data.data.uid', ''),
        user_id: get(response, 'data.data.user.user_id', ''),
        username: get(response, 'data.data.user.username', ''),
        remaining_time: get(response, 'data.data.remaining_time', 0),
        extended_elapsed_time: get(
          response,
          'data.data.extended_elapsed_time',
          0,
        ),
      })
      actions.setLoading(false)
    } catch (error) {
      if (
        ['FAIL_UNABLE_JOIN_LATE', 'BAD_REQUEST'].indexOf(
          get(error, 'response.data.code', ''),
        ) > -1
      ) {
        actions.setJoinedLate(true)
      } else if (
        get(error, 'response.data.code', '') ===
        'FAIL_UNABLE_JOIN_EARLY'
      ) {
        actions.setJoinedEarly(true)
      } else if (
        get(error, 'response.data.code', '') ===
        'FAIL_UNABLE_JOIN_SESSION'
      ) {
        actions.setSessionEnd(true)
      } else if (
        get(error, 'response.data.code', '') === 'NOT_FOUND'
      ) {
        actions.setParticipantNotBooking(true)
      } else {
        if (error?.response?.status === 500) {
          actions.setError('internal server error')
        } else {
          actions.setError(error.message)
        }
      }
    }
  }),
}

export default JoinSession
