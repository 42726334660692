import { styled } from '@mui/material/styles'
import CoinIcon from 'assets/svg/session/coin.svg'
import AddCoinIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ReduceCoinIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import IconButton from '@mui/material/IconButton'
import { useStoreActions, useStoreState } from 'stores/hooks'
import CoinPackageSkeleton from 'components/domains/Wallet/CoinPackage/Skeleton'
import topupConfig from 'configs/topup'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

type ContainerProps = {
  ispackageactive?: string
}

const StyledContainer = styled(Box)<ContainerProps>(
  ({ ispackageactive = '', theme }) => ({
    width: '100%',
    height: '80px',
    background: 'white',
    borderRadius: '8px',
    marginBottom: '8px',
    border:
      ispackageactive === 'true'
        ? `1px solid ${theme.palette.primary.main}`
        : 'none',
  }),
)

const CoinIconContainer = styled(Stack)({
  width: '100%',
  marginLeft: '18px',
  svg: {
    width: '24px',
    height: '24px',
  },
})
const CoinInfoContainer = styled(Stack)({
  width: '50%',
})
const CoinPriceContainer = styled(Stack)({
  width: '50%',
})

const TopUpButtonsContainer = styled(Stack)({
  height: '80px',
})

const QuantityContainer = styled(Stack)(({ theme }) => ({
  svg: {
    width: '28px',
    height: '28px',
    color: theme.palette.primary.main,
  },
}))
const CoinPriceLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 'bold',
  marginLeft: '18px',
}))
const CoinValueLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '24px',
}))

const StyledStack = styled(Stack)({
  width: '100%',
})

const PriceCurrency = styled('span')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '12px',
  color: theme.palette.tiptip[500],
}))

interface ICoinPackage {
  id: string
  coin_unit: number
  purchase_price: number
  original_price: number
  discount: number
  currency_label: string
  quantity?: number
  totalCoinTopUp?: number
  max_total_coin_purchase?: number
}

function CoinPackage({
  id,
  coin_unit,
  purchase_price,
  currency_label,
  quantity,
  totalCoinTopUp,
  max_total_coin_purchase,
}: ICoinPackage) {
  const { addCoinPackages, reduceCoinPackages } = useStoreActions(
    (actions) => actions.topUp,
  )
  const handleAddCoinPackageButtonClick = () => {
    addCoinPackages(id)
  }
  const handleReduceCoinPackageButtonClick = () => {
    reduceCoinPackages(id)
  }
  const { isLoading } = useStoreState((actions) => actions.topUp)

  const formatCurrency = (value) => {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  return (
    <>
      {isLoading ? (
        <CoinPackageSkeleton />
      ) : (
        <StyledContainer
          ispackageactive={quantity > 0 ? 'true' : 'false'}
        >
          <StyledStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <CoinInfoContainer
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
            >
              <CoinIconContainer
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={1}
              >
                <CoinIcon />
                <CoinValueLabel className="c-wallet-coin-value-label">
                  {coin_unit.toLocaleString()}
                </CoinValueLabel>
              </CoinIconContainer>
              <CoinPriceContainer
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={1}
              >
                <CoinPriceLabel className="c-wallet-coin-price-label">
                  <PriceCurrency>{currency_label}</PriceCurrency>
                  {formatCurrency(purchase_price)}
                </CoinPriceLabel>
              </CoinPriceContainer>
            </CoinInfoContainer>

            <TopUpButtonsContainer
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {quantity > 0 ? (
                <QuantityContainer
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={1}
                  alignSelf={'flex-end'}
                >
                  <IconButton
                    disabled={quantity <= 0}
                    onClick={handleReduceCoinPackageButtonClick}
                    className="b-wallet-reduce-coin-button"
                  >
                    <ReduceCoinIcon fontSize="medium" />
                  </IconButton>
                  <CoinPriceLabel className="c-wallet-quantity-coin-label">
                    {quantity}
                  </CoinPriceLabel>
                  <IconButton
                    disabled={
                      quantity >= topupConfig.maxCoinPackage ||
                      totalCoinTopUp > max_total_coin_purchase
                    }
                    onClick={handleAddCoinPackageButtonClick}
                    className="b-wallet-add-coin-button"
                  >
                    <AddCoinIcon fontSize="medium" />
                  </IconButton>
                </QuantityContainer>
              ) : (
                <Button
                  buttonVariant="outlined"
                  onClick={handleAddCoinPackageButtonClick}
                  className="b-wallet-top-up-coin-button"
                  disabled={totalCoinTopUp > max_total_coin_purchase}
                  sx={{ alignSelf: 'flex-end' }}
                >
                  Tambah
                </Button>
              )}
            </TopUpButtonsContainer>
          </StyledStack>
        </StyledContainer>
      )}
    </>
  )
}

export default CoinPackage
