import { SxProps, styled } from '@mui/material'
import StateImage from 'components/commons/StateImage'
import ActionButton from '../Button/Action'
import Box from 'components/commons/Box'
import {
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import Stack from '../Stack'

const StyledStateImage = ({ type }) => (
  <StateImage
    type={type}
    sx={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '24px',
    }}
  />
)

const ContainerBtn = styled(Stack)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  marginTop: '24px',
  justifyContent: 'space-between',
})

export default function ErrorResponseCode({
  code,
  showCTA,
  onCTA,
  data,
  sxContainer,
  t,
}: {
  code?: string
  showCTA?: boolean
  onCTA?: Function
  data?: any
  sxContainer?: SxProps
  t?: any
}) {
  switch (code) {
    case 'INSUFFICIENT_QUOTA':
      return (
        <Box sx={sxContainer}>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>
            {t('eventTicketSelectionSoldOutTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventTicketSelectionSoldOutDescription')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('back_to_event')}
              >
                {t('eventParticipantFormBackToEvent')}
              </ActionButton>
              <ActionButton
                fullWidth
                onClick={() => onCTA('browse_events')}
              >
                {t('eventTicketSelectionSeeOtherEvent')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'PRODUCT_UNAVAILABLE':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>{t('eventUnpublishedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUnpublishedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('browse_events')}
              >
                {t('eventUnpublishedButtonLabel')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'SALE_NOT_STARTED':
      return (
        <Box>
          <StyledStateImage type="date-time" />
          <ErrorLabel>
            {t('eventDetailsErrorSaleNotStartedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventDetailsErrorSaleNotStartedSubtitle')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'USER_OVERBOOKED':
      return (
        <Box>
          <StyledStateImage type="ticket-not-available" />
          <ErrorLabel>{t('eventUserOverbookedTitle')}</ErrorLabel>
          <ErrorDesc>{t('eventUserOverbookedDescription')}</ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                {t('close')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'INVALID_SALES_PERIOD':
      return (
        <Box>
          <StyledStateImage type="calendar" />
          <ErrorLabel>
            {t('eventTicketSelectionSalesEndedTitle')}
          </ErrorLabel>
          <ErrorDesc>
            {t('eventTicketSelectionSalesEndedDescription')}
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                onClick={() => onCTA('back_to_event')}
              >
                {t('eventParticipantFormBackToEvent')}
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    case 'ID_NUMBER_ALREADY_USED':
      return (
        <Box>
          <StyledStateImage type="user-alert" />
          <ErrorLabel>Nomor KTP/Paspor Sudah Digunakan</ErrorLabel>
          <ErrorDesc>
            Nomor KTP/Paspor {data.id_card.join(', ')} sudah
            digunakan. Silakan masukkan nomor yang sesuai dengan
            pemegang tiket.
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton
                fullWidth
                buttonVariant="outlined"
                onClick={() => onCTA('contact_cs')}
              >
                Hubungi CS
              </ActionButton>
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                Ok, saya mengerti
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
    default:
      return (
        <Box>
          <StyledStateImage type="system-error" />
          <ErrorLabel>Terjadi kesalahan sistem</ErrorLabel>
          <ErrorDesc>
            Maaf terjadi kesalahan pada sistem saat menampilkan
            halaman. Silakan coba beberapa saat lagi
          </ErrorDesc>
          {showCTA && (
            <ContainerBtn gap="16px">
              <ActionButton fullWidth onClick={() => onCTA('close')}>
                Tutup
              </ActionButton>
            </ContainerBtn>
          )}
        </Box>
      )
  }
}
