import { styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

export const TitleLabelWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 24,
})
export const StyledCheck = styled(CheckCircleIcon)(({ theme }) => ({
  fill: `${theme.palette.success.main} !important`,
  marginLeft: '5px',
}))
export const TitleLabel = styled(Box)(({ theme }) => ({
  ...theme.typography.h3,
  lineHeight: theme.typography.mediumRegular.lineHeight,
  textAlign: 'center',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
}))

export const CoinPriceLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  fontSize: 14,
  marginBottom: 15,
  ...theme.typography.normalRegular,
}))
export const CoinPriceValue = styled(Box)(({ theme }) => ({
  ...theme.typography.normalBold,
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
