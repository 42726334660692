import React from 'react'
import dynamic from 'next/dynamic'

import Layout from 'components/commons/Layout'
import NetworkError from 'screens/commons/NetworkError'

import appConfig from 'configs/app'
import { sendError } from 'helpers/log'

// TODO Fix Next/Dynamic testing
/* istanbul ignore next */
const ErrorBoundaryContainer = dynamic(
  () => import('screens/commons/GeneralError'),
  // eslint-disable-next-line react/display-name
  { loading: () => <Layout isLoading={true} /> },
)

type Props = {
  children: Node | Element | JSX.Element
}

type State = {
  error: Error
  info: Object
  isOffline?: boolean
}
/**
 * @return {React.Node}
 */
export default class ErrorBoundary extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null, info: null, isOffline: false }
  }

  componentDidMount(): void {
    if (!appConfig.isServerSide) {
      window.addEventListener('offline', () => {
        this.setState({ isOffline: true })
      })

      window.addEventListener('online', () => {
        this.setState({ isOffline: false })
      })
    }
  }

  componentDidCatch(
    error: Error,
    info: Object,
    isOffline?: boolean,
  ): void {
    this.setState({ error, info, isOffline })
    sendError(error)
  }

  render(): React.ReactNode {
    const { error, info, isOffline } = this.state
    const { children } = this.props

    if (isOffline) {
      return <NetworkError />
    }
    if (error) {
      return <ErrorBoundaryContainer info={info} error={error} />
    }
    return children
  }
}
