import { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { AppBar, Tab, useTheme } from '@mui/material'

import { useNotificationStore } from 'stores/commons'
import { ITabNotificationProps } from 'constants/notification'
import redirect from 'helpers/redirect'

import { StyledTabs, TabPanel } from './styled'
import InformationNotification from './InformationNotification'
import TransactionNotification from './TransactionNotification'
import Typography from 'components/commons/Typography'
import Box from 'components/commons/Box'
import { useTranslation } from 'react-i18next'

const NotificationContainer = ({
  positionActionButton,
  isHeader = false,
}: ITabNotificationProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [isFirstRender, setIsFirstRender] = useState(false)

  const {
    totalUnreadInformationNotification,
    totalUnreadTransactionNotification,
    getListNotification,
  } = useNotificationStore((state) => ({
    totalUnreadInformationNotification:
      state.totalUnreadInformationNotification,
    totalUnreadTransactionNotification:
      state.totalUnreadTransactionNotification,
    getListNotification: state.getListNotification,
  }))

  const handleChangeSwipeTab = (currentIndex: number) =>
    setSelectedTab(currentIndex)

  const handleChangeTab = (_e, newvalue) => {
    setSelectedTab(newvalue)
  }

  const handleEmptyNotification = () => {
    redirect('/browse?status=all')
  }

  function a11yProps(status: string) {
    return {
      id: `b-browse-tabpanel-${status.toLowerCase()}`,
      'aria-controls': `b-browse-tabpanel-${status.toLowerCase()}`,
    }
  }

  useEffect(() => {
    if (!isFirstRender) setIsFirstRender(true)
  }, [])

  useEffect(() => {
    if (isFirstRender && isHeader) {
      getListNotification({
        limit: 5,
        page: 1,
        type: 2,
        isReset: true,
        isActive: true,
      })
      setIsFirstRender(false)
    }
  }, [isFirstRender])

  return (
    <>
      <Typography
        sx={(theme) => ({
          display: {
            xs: 'none',
            md: 'block',
          },
          ...theme.typography.h3Desk,
          color: theme.palette.text.primary,
          padding: '16px',
        })}
      >
        {t('notifications')}
      </Typography>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          borderBottom: `1px solid ${theme.palette.background.tertiery}`,
          marginTop: {
            xs: '56px',
            md: '0px',
          },
        }}
      >
        <StyledTabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: {
              background: theme.palette.tiptip[500],
            },
          }}
        >
          <Tab
            sx={{
              width: { xs: `50%` },
              padding: '12px',
              minWidth: 0,
            }}
            iconPosition="start"
            key={String(`browse-tab-item-transaction`)}
            label={
              totalUnreadTransactionNotification > 0
                ? `${t(
                    'notificationScreenTransactionTabTitle',
                  )} (${totalUnreadTransactionNotification})`
                : t('notificationScreenTransactionTabTitle')
            }
            {...a11yProps('transaction')}
          />
          <Tab
            sx={{
              width: { xs: `50%` },
              padding: '12px',
              minWidth: 0,
            }}
            iconPosition="start"
            key={String(`browse-tab-item-information`)}
            label={
              totalUnreadInformationNotification > 0
                ? `${t(
                    'notificationScreenInformationTabTitle',
                  )} (${totalUnreadInformationNotification})`
                : t('notificationScreenInformationTabTitle')
            }
            {...a11yProps('information')}
          />
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={selectedTab}
        onChangeIndex={handleChangeSwipeTab}
      >
        <TabPanel
          role="tabpanel"
          id={`b-notification-tabpanel-transaction`}
          aria-labelledby={`b-notification-tabpanel-transaction`}
          height={'unset !important'}
        >
          <Box>
            {selectedTab === 0 && (
              <TransactionNotification
                positionActionButton={positionActionButton}
                onClickEmptyNotification={handleEmptyNotification}
                active={selectedTab === 0}
                isHeader={isHeader}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel
          role="tabpanel"
          id={`b-notification-tabpanel-information`}
          aria-labelledby={`b-notification-tabpanel-information`}
          height={'unset !important'}
        >
          <Box>
            {selectedTab === 1 && (
              <InformationNotification
                positionActionButton={positionActionButton}
                onClickEmptyNotification={handleEmptyNotification}
                active={selectedTab === 1}
                isHeader={isHeader}
              />
            )}
          </Box>
        </TabPanel>
      </SwipeableViews>
    </>
  )
}

export default NotificationContainer
