import { useEffect, useState } from 'react'
import { styled, SxProps } from '@mui/material'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'

const StyledCountDown = styled(Stack)({
  gap: 3,
})

interface ICountdownTimerProps {
  remainingTime: number
  sx?: SxProps
  withText?: boolean
  onTimerFinished?: VoidFunction
}
function CountdownTimer(props: ICountdownTimerProps) {
  const [timeLeft, setTimeLeft] = useState(props.remainingTime)
  const [isFinished, setFinished] = useState(false)

  const hours = Math.floor(timeLeft / 3600)
  const minutes = Math.floor((timeLeft % 3600) / 60)
  const seconds = timeLeft % 60

  const hDisplay = hours >= 10 ? hours : '0' + hours
  const mDisplay = minutes >= 10 ? minutes : '0' + minutes
  const sDisplay = seconds >= 10 ? seconds : '0' + seconds

  useEffect(() => {
    if (timeLeft <= 0) {
      setFinished(true)
      if (props.onTimerFinished) props.onTimerFinished()

      return
    }
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft])

  if (!isFinished) {
    if (props.withText) {
      return (
        <StyledCountDown direction={'row'} sx={props.sx}>
          <Typography>
            {hDisplay} jam : {mDisplay} menit : {sDisplay} detik
          </Typography>
        </StyledCountDown>
      )
    }
    return (
      <StyledCountDown direction={'row'} sx={props.sx}>
        <Typography>{hDisplay} :</Typography>
        <Typography>{mDisplay} : </Typography>
        <Typography>{sDisplay}</Typography>
      </StyledCountDown>
    )
  }

  return (
    <StyledCountDown direction={'row'} sx={props.sx}>
      <Typography>00 : 00 : 00</Typography>
    </StyledCountDown>
  )
}

export default CountdownTimer
