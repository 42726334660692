import * as React from 'react'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import { styled } from '@mui/material/styles'
import ErrorBannerIcon from 'assets/svg/session/session-unload.svg'

import { useStoreActions } from 'stores/hooks'
import Button from 'components/commons/Button'

const InvalidLoadTippingWrapper = styled(Stack)({
  width: '100%',
  textAlign: 'center',
  padding: '30px 0px',
})

const CloseButton = styled(Button)({
  marginTop: '20px',
  padding: '8px 40px',
  borderRadius: '50px',
  color: '#FFFFFF',
  border: '1px solid #CB1C4F',
})

function PaymentConfirmationDrawerContent() {
  const { getCoinPackages } = useStoreActions(
    (actions) => actions.topUp,
  )

  const handleOnRefresh = () => {
    getCoinPackages()
  }
  return (
    <InvalidLoadTippingWrapper
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <ErrorBannerIcon />
      <Typography
        fontWeight={700}
        fontSize="20px"
        color="primary"
        sx={{
          marginTop: '20px',
        }}
      >
        Pilihan paket Coin gagal ditampilkan
      </Typography>
      <Typography fontSize="14px" color="#secondary">
        Mohon refresh untuk memuat ulang.
      </Typography>
      <CloseButton onClick={handleOnRefresh}>Refresh</CloseButton>
    </InvalidLoadTippingWrapper>
  )
}

export default PaymentConfirmationDrawerContent
