import { useFormContext, Controller } from 'react-hook-form'
import {
  Breakpoint,
  FormControl,
  FormHelperText,
  SxProps,
  TextareaAutosize,
  TextareaAutosizeProps,
  styled,
} from '@mui/material'
import Typography from 'components/commons/CustomTypography'
import { StyledInputLabel } from '../styled'
import { useState } from 'react'

const StyledTextarea = styled(TextareaAutosize)<{
  error?: boolean
}>(({ theme, error = false }) => ({
  ...theme.typography.normalRegular,
  padding: '12px',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.tertiery,
  borderRadius: 6,
  borderWidth: '1px',
  borderColor: error ? theme.palette.error.main : 'transparent',
  marginTop: '16px',

  '&:focus, &:hover': {
    borderWidth: '1px',
    borderColor: error ? theme.palette.error.main : 'transparent',
  },
  '&:focus-visible': {
    outline: 0,
  },
  '::placeholder': {
    color: theme.palette.text.tertiery,
  },
}))

const StyledTextAreaCount = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  ...theme.typography.smallRegular,
  color: theme.palette.text.tertiery,
  background: theme.palette.background.tertiery,
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  padding: '8px 12px',
}))

type ResizeType =
  | 'block'
  | 'both'
  | 'horizontal'
  | 'inline'
  | 'none'
  | 'vertical'

type IProps = {
  name: string
  label?: string
  info?: string
  resize?: ResizeType
  mobileIn?: Breakpoint
  maxCount?: Number
  disableCounter?: boolean
  sx?: SxProps
}

type Props = IProps & TextareaAutosizeProps

export default function RHFTextArea({
  name,
  label,
  info,
  id,
  resize = 'none',
  required,
  maxCount = 2048,
  disableCounter,
  sx,
  ...other
}: Props) {
  const { control } = useFormContext()

  const [textAreaCount, setTextAreaCount] = useState(0)

  const recalculateLength = (e, onChange) => {
    setTextAreaCount(e.target.value.length)
    onChange(e)
  }

  const renderErrorText = (message: string | undefined) => {
    if (message) return <FormHelperText>{message}</FormHelperText>
  }

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <StyledInputLabel shrink htmlFor={id} required={required}>
          {label}
        </StyledInputLabel>
      )}
      {info && (
        <Typography
          as="span"
          size="xs"
          sx={(theme) => ({
            position: 'absolute',
            right: 0,
            top: 0,
            color: theme.palette.text.secondary,
          })}
        >
          {info}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <StyledTextarea
              {...field}
              id={id}
              style={{
                resize: resize,
                overflow: 'auto',
              }}
              error={!!error}
              required={required}
              sx={{
                marginTop: '20px !important',
                fontFamily: '"Source Sans 3" !important',
                ...sx,
                borderBottom: 'none',
                borderBottomRightRadius: '0px',
                borderBottomLeftRadius: '0px',
              }}
              onChange={(e) => recalculateLength(e, field.onChange)}
              {...other}
            />
            {!disableCounter && (
              <StyledTextAreaCount sx={{ ...sx, borderTop: 'none' }}>
                {textAreaCount}/{maxCount}
              </StyledTextAreaCount>
            )}
            {renderErrorText(error?.message)}
          </>
        )}
      />
    </FormControl>
  )
}
