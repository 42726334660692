import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import {
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'

export default function VoucherErrorContent() {
  return (
    <Box>
      <StateImage
        type="error"
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <ErrorLabel>Gagal menggunakan voucher</ErrorLabel>
      <ErrorDesc>
        Kode yang kamu terapkan dihapus dari transaksi karena limit
        waktu habis, silakan masukkan ulang kode voucher.
      </ErrorDesc>
    </Box>
  )
}
