import { action, thunk } from 'easy-peasy'
import isEmpty from 'helpers/is-empty'
import ICreatorProfileModel from 'stores/domains/Creator/Profile/interface'
import redirect from 'helpers/redirect'
import { getIdToken } from 'helpers/auth'

const CreatorProfileModel: ICreatorProfileModel = {
  isLoading: true,
  isError: false,
  isUserHasOfflineSession: false,
  creatorProfileData: {
    creator: {
      creator_id: '',
      creator_user_id: '',
      creator_group: '',
      name: '',
      header_image: '',
      intro_video: '',
      bio: '',
      status: '',
      reason: '',
      self: false,
      creator_class: 'PERSONAL',
      social_media: {
        facebook: {
          link: '',
        },
        twitter: {
          link: '',
        },
        instagram: {
          link: '',
        },
        youtube: {
          link: '',
        },
        linkedin: {
          link: '',
        },
        tiktok: {
          link: '',
        },
      },
      verified: false,
      profile_image_thumbnail: '',
    },
    user: {
      username: '',
      status: 1,
      is_community_leader: '',
    },
    category: {
      id: 0,
      icon: '',
      name: '',
    },
    follower: {
      total: 0,
      is_followed: false,
    },
  },
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setCreatorProfile: action((state, payload) => {
    state.creatorProfileData = payload
  }),
  setIsUserHasOfflineSession: action((state, payload) => {
    state.isUserHasOfflineSession = payload
  }),
  getCreatorProfile: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        let idToken = getIdToken()
        let url = ''
        if (!isEmpty(idToken)) {
          url = '/user/v1/creator/' + payload.username + '/profile'
        } else {
          url =
            'user/guest/v1/creator/' + payload.username + '/profile'
          idToken = ''
        }
        const { apiClient } = injections
        const response = await apiClient({
          url: url,
          method: 'GET',
          token: idToken,
        })
        if (response?.data?.code === 'SUCCESS') {
          actions.setCreatorProfile(response.data?.data)
        }
      } catch (error) {
        if (error?.response?.data?.code === 'NOT_FOUND') {
          // USER NOT FOUND
          redirect('/profile/not-found')
        } else {
          // TO DO Action for another error
        }
      } finally {
        actions.setLoading(false)
      }
    },
  ),
  resetCreatorProfile: action((state) => {
    state.isLoading = true
    state.isError = false
    state.errorMessage = ''
    state.creatorProfileData = {
      creator: {
        creator_id: '',
        creator_user_id: '',
        creator_group: '',
        name: '',
        header_image: '',
        intro_video: '',
        bio: '',
        status: '',
        reason: '',
        self: false,
        social_media: {
          facebook: {
            link: '',
          },
          twitter: {
            link: '',
          },
          instagram: {
            link: '',
          },
          youtube: {
            link: '',
          },
          linkedin: {
            link: '',
          },
          tiktok: {
            link: '',
          },
        },
        verified: false,
        profile_image_thumbnail: '',
        creator_class: 'PERSONAL',
      },
      user: {
        username: '',
        status: 1,
        is_community_leader: '',
      },
      category: {
        id: 0,
        icon: '',
        name: '',
      },
      follower: {
        total: 0,
        is_followed: false,
      },
    }
  }),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default CreatorProfileModel
