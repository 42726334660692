import { action } from 'easy-peasy'
import IEventPersonalQuestionsModel from './interface'

const EventPersonalQuestionsModel: IEventPersonalQuestionsModel = {
  userAdditionalInfo: {
    fullName: '',
    phoneNumber: '',
    email: '',
  },
  userContactInfo: {
    fullName: '',
    phoneNumber: '',
    email: '',
  },
  sendToAdditionalEmail: false,
  setSendToAdditionalEmail: action((state, payload) => {
    state.sendToAdditionalEmail = payload
  }),
  setUserContactInfo: action((state, payload) => {
    state.userContactInfo = payload
  }),
  addUserAdditionalInfo: action((state, payload) => {
    state.userAdditionalInfo = payload
  }),
  clearUserAdditionalInfo: action((state) => {
    state.userAdditionalInfo = {
      fullName: '',
      phoneNumber: '',
      email: '',
    }
  }),
}

export default EventPersonalQuestionsModel
