import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useStoreActions, useStoreState } from 'stores/hooks'

import {
  useProfileUserStore,
  useReferralStore,
} from 'stores/domains/User'
import { useProfileUser } from 'hooks/domains/User'
import useCommunity from 'hooks/domains/Community/useCommunity'
import ReferralCodeInfo from 'containers/domains/User/ReferralCodeInfo'
import TopUpDrawer from 'containers/domains/Wallet/TopUpDrawer'
import ReferralCodeDrawer from 'containers/domains/User/ReferralCodeDrawer'
import ReferralCodeListDrawer from 'containers/domains/User/ReferralCodeListDrawer'
import ReferralCodeShareDrawer from 'containers/domains/User/ReferralCodeShareDrawer'
import Stack from '../Stack'

import trackEvent from 'src/trackers'
import {
  MenuCoin,
  MenuItem,
  MenuProfile,
  MenuProfileProps,
  MenuReferral,
  MenuSignOut,
} from './Menu'
import {
  menuBusinessActivities,
  menuCreatorActivities,
  menuGeneral,
  menuHelpCenter,
  menuPersonalActivities,
} from './menuItems'
import SubmitReferralCodeDrawer from 'containers/domains/User/SubmitReferralCodeDrawer'
import isEmpty from 'helpers/is-empty'
import SuggestFollowModal from 'containers/domains/Community/SuggestFollowModal'
import { StyledSectionTitle } from './Menu/styled'
import MultipleLanguageDrawer from 'containers/domains/User/MultipleLanguageDrawer'
import { useTranslation } from 'react-i18next'

type IProps = {
  sidebar?: boolean
  dark?: boolean
  signOut?: boolean
}

type MenuNavigationProps = IProps &
  Omit<MenuProfileProps, 'profileData'>

function MenuNavigation({
  fullWidth = false,
  sidebar = false,
  dark = false,
  signOut = false,
  ...rest
}: MenuNavigationProps) {
  const { t } = useTranslation()

  const { isReady, pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const trackerBaseProperties = {
    modal_name: 'USER_NAVIGATION_MENU',
  }

  const { refetch: refetchProfileUser } = useProfileUser()
  const {
    profileData,
    isNewCreatorDrawer,
    isLoading: isLoadingProfile,
  } = useProfileUserStore((state) => ({
    profileData: state.profileData,
    isLoading: state.isLoading,
    isNewCreatorDrawer: state.isNewCreatorDrawer,
  }))

  const { hasCommunity, isErrorCommunityInfo, isLoading } =
    useCommunity()
  const hasValidCommunity = hasCommunity && !isErrorCommunityInfo

  const { referralCodeInfo, showReferralExistDrawer } =
    useReferralStore((state) => ({
      referralCodeInfo: state.referralCodeInfo,
      showReferralExistDrawer: state.showReferralExistDrawer,
    }))

  const {
    setShowTopUpModal,
    getCoinBalance,
    setShowReferralCodeListDrawer,
  } = useStoreActions((actions) => ({
    ...actions.topUp,
    ...actions.coinBalance,
    ...actions.referralCode,
  }))

  const { CoinBalance } = useStoreState(
    (actions) => actions.coinBalance,
  )

  const handleTopUpCoin = () => {
    setShowTopUpModal(true)
    trackEvent.user('click_topup', trackerData, trackerBaseProperties)
  }

  const handleReferralCode = () => {
    setShowReferralCodeListDrawer(true)
    trackEvent.user(
      'click_referral_code_fill_in',
      trackerData,
      trackerBaseProperties,
    )
  }

  const renderMenuByRole = () => {
    switch (profileData?.creator?.creator_class) {
      case 'CREATOR':
        return [
          ...menuGeneral(),
          ...menuCreatorActivities(profileData, pathname),
        ]
      case 'BUSINESS':
        return [
          ...menuGeneral(),
          ...menuBusinessActivities(profileData, pathname),
        ]
      case 'PERSONAL':
      default:
        return [
          ...menuGeneral(),
          ...menuPersonalActivities(profileData, pathname),
        ]
    }
  }

  useEffect(() => {
    if (isReady) {
      getCoinBalance()

      if (isNewCreatorDrawer) refetchProfileUser()
    }
  }, [isReady])

  if (isLoading) return null

  return (
    <Stack
      p={0}
      spacing={2}
      maxWidth={fullWidth ? '100%' : '312px'}
      pt={{ sm: fullWidth ? 1 : 0, md: 0 }}
      pb={{ xs: fullWidth ? 7 : 0, md: 0 }}
      {...rest}
    >
      <MenuProfile
        profileData={profileData}
        flex={1}
        fullWidth={fullWidth}
        dark={dark}
        sidebar={sidebar}
      />
      <Stack direction={sidebar ? 'column' : 'row'} spacing={1}>
        <MenuCoin
          balance={CoinBalance}
          onClick={handleTopUpCoin}
          id="b-wallet-top-up-coin"
          narrow={sidebar}
          dark={dark}
        />
        {profileData?.isCreator && (
          <MenuReferral
            referral={profileData?.referral_code}
            isLoading={isLoadingProfile}
            id="b-wallet-referral-code"
            onClick={handleReferralCode}
            narrow={sidebar}
            dark={dark}
          />
        )}
      </Stack>
      {!sidebar &&
        !isEmpty(profileData.username) &&
        isEmpty(profileData.referrer_code) && (
          <ReferralCodeInfo t={t} />
        )}
      <StyledSectionTitle>{t('myActivity')}</StyledSectionTitle>
      <Stack spacing={1}>
        {renderMenuByRole().map((menu, index) =>
          menu.title === 'Mission' ? (
            hasValidCommunity ? (
              <MenuItem key={index} dark={dark} {...menu} />
            ) : null
          ) : (
            <MenuItem key={index} dark={dark} {...menu} />
          ),
        )}
      </Stack>
      <StyledSectionTitle>{t('supportCenter')}</StyledSectionTitle>
      <Stack spacing={1}>
        {menuHelpCenter.map((menu, index) => (
          <MenuItem key={index} dark={dark} {...menu} />
        ))}
      </Stack>
      {signOut && <MenuSignOut />}
      <TopUpDrawer t={t} />
      <ReferralCodeDrawer t={t} />
      <ReferralCodeListDrawer />
      <ReferralCodeShareDrawer />
      <SubmitReferralCodeDrawer t={t} />
      <MultipleLanguageDrawer />

      {referralCodeInfo?.community?.mask_id &&
        !showReferralExistDrawer && (
          <SuggestFollowModal
            image={referralCodeInfo.community.thumbnail_image}
            name={referralCodeInfo.community.name}
            type="referral"
            communityId={referralCodeInfo.community.mask_id}
            customSubtitle="Yang mengundang kamu merupakan pemilik komunitas ini, yuk gabung!"
          />
        )}
    </Stack>
  )
}

export default MenuNavigation
