/**
 * Returns error message for all auth
 *
 * @param code // Error code from API
 * @param additionalProperties
 */

export type ErrorCode =
  | 'USER_REQUEST_OTP_REACH_MAXIMUM'
  | 'FRAUD_USER_PHONE_NUMBER_DETECTED'
  | 'USER_VERIFY_OTP_REACH_MAXIMUM'
  | 'INVALID_PHONE_NUMBER_FORMAT'
  | 'TOO_MANY_REQUESTS'
  | 'USER_IS_SUSPENDED'
  | 'UNABLE_ADD_EMAIL'
  | 'SUGGEST_PHONE'
  | 'INCORRECT_USERNAME_OR_PASSWORD'
  | 'NOT_FOUND'
  | 'EMAIL_ALREADY_EXIST'
  | 'INVALID_CODE'
  | 'EXPIRED_CODE'
  | 'PHONE_NUMBER_ALREADY_USED'

interface IAdditionalProperties {
  seconds?: number
  defaultMessage?: string
  isEmail?: boolean
  isChangePassword?: boolean
  isForgotPassword?: boolean
}

const renderErrorMessage = (
  code: ErrorCode,
  additionalProperties: IAdditionalProperties = {
    seconds: 0,
    defaultMessage: '',
    isEmail: false,
    isChangePassword: false,
    isForgotPassword: false,
  },
) => {
  const {
    seconds,
    defaultMessage,
    isEmail,
    isChangePassword,
    isForgotPassword,
  } = additionalProperties
  let message = ''
  switch (code) {
    case 'USER_REQUEST_OTP_REACH_MAXIMUM':
    case 'USER_VERIFY_OTP_REACH_MAXIMUM':
      const counter = seconds > 60 ? Math.ceil(seconds / 60) : seconds
      message = `Oops, sepertinya kamu melebihi batasan percobaan! Hubungi kami untuk bantuan atau coba lagi untuk mengirimkan kode OTP setelah ${counter} ${
        seconds > 60 ? 'menit' : 'detik'
      }.`
      break
    case 'FRAUD_USER_PHONE_NUMBER_DETECTED':
      message =
        'Informasi pendaftaran Anda berpotensi tidak aman, cobalah mendaftar dengan nomor telepon yang berbeda.'
      break
    case 'INVALID_PHONE_NUMBER_FORMAT':
      message = 'Masukan nomor HP yang valid'
      break
    case 'TOO_MANY_REQUESTS':
      message = isEmail
        ? 'Password salah beberapa kali. Mohon tunggu beberapa saat untuk mencoba lagi atau pilih opsi "Lupa Password".'
        : isChangePassword
        ? 'Password salah beberapa kali. Mohon tunggu beberapa saat untuk mencoba lagi.'
        : isForgotPassword
        ? `Mohon tunggu ${seconds} detik dan silakan coba masukkan kembali email kamu`
        : `Mohon tunggu ${seconds} detik dan silakan coba masukkan kembali nomor HP kamu`
      break
    case 'USER_IS_SUSPENDED':
      message = 'Mohon maaf, akun ini telah ditangguhkan'
      break
    case 'UNABLE_ADD_EMAIL':
      message =
        'Maaf, Kamu tidak dapat melakukan update email untuk saat ini karena terintegrasi dengan Google SSO/AppleID.'
      break
    case 'SUGGEST_PHONE':
      message =
        'Dapatkan pengalaman yang optimal dengan melengkapi nomor HP kamu'
      break
    case 'INCORRECT_USERNAME_OR_PASSWORD':
    case 'NOT_FOUND':
      message = isForgotPassword
        ? 'Email tidak ditemukan atau masuk dengan menggunakan metode lain'
        : 'Email atau Password yang kamu masukkan salah'
      break
    case 'EMAIL_ALREADY_EXIST':
      message =
        'Email yang kamu gunakan sudah terdaftar. Silakan gunakan email yang lain.'
      break
    case 'INVALID_CODE':
      message =
        'Kode tidak valid. Pastikan kode sesuai dan coba lagi.'
      break
    case 'EXPIRED_CODE':
      message = 'Kode OTP telah expired.'
      break
    case 'PHONE_NUMBER_ALREADY_USED':
      message = 'Nomor HP yang kamu masukan sudah terdaftar'
      break
    default:
      message =
        defaultMessage ||
        'Ups, terjadi kesalahan, coba beberapa saat lagi'
      break
  }
  return message
}

export { renderErrorMessage }
