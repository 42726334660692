import { Link } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(Link)(({ theme }) => ({
  textDecorationColor: theme.palette.info.main,
}))

const linkify = (
  text: string,
  callback: Function = () => {},
  newTab: Boolean = true,
) => {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  return text.split(urlRegex).map((part, index) =>
    index % 2 === 0 ? (
      part
    ) : (
      <StyledLink
        href={
          newTab ? `/external-link?url=${validateURL(part)}` : '#'
        }
        target={newTab ? `_blank` : ``}
        rel="noreferrer"
        onClick={() => {
          callback(part)
        }}
        color={'info'}
      >
        {part}
      </StyledLink>
    ),
  )
}

const validateURL = (url: string) => {
  if (url.slice(0, 4) == 'www.') {
    return `http://${url.slice(4)}`
  }
  return url
}

export { linkify, validateURL }
