import * as React from 'react'
import { useEffect, useState } from 'react'
import htmlParse from 'html-react-parser'
import VoucherCard from 'components/domains/User/VoucherCard'
import {
  MainTypography,
  StyledDivider,
  SubTypography,
} from './styled'
import trackEvent from 'src/trackers'
import { useTheme } from '@mui/material/styles'
import { Alert, Collapse } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PageNotFound from 'screens/commons/PageNotFound'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { IProductsProps } from 'services/payment'
import { getIdToken } from 'helpers/auth'
import Box from 'components/commons/Box'

function numberFormatter(num) {
  return new Intl.NumberFormat('id-ID').format(num)
}

export interface IVoucherDetails {
  voucherDetailClose?: Function
  trackerData?: any
  page?: boolean
  selectedProducts?: {
    products: Array<IProductsProps>
    payment_method: string
    checkout_source: string
    user_mask_id?: string
    voucher_code: string
    idempotentKey: string
  }
  idempotentKey: string
  itemType?: string
  community?: any
}

export default function VoucherDetails({
  voucherDetailClose,
  trackerData,
  page,
  selectedProducts,
  idempotentKey,
  itemType = 'content',
  community,
}: IVoucherDetails) {
  const theme = useTheme()
  const [selectedVoucher, setSelectedVoucher] = useState(null)
  const [alertVoucher, setAlertVoucher] = useState(false)
  const [isCardCheck, setIsCardCheck] = useState(false)

  const {
    isLoading,
    errorMessage,
    isError,
    voucherData,
    voucherDetails,
  } = useStoreState((state) => state.voucherModel)

  const { setVoucherData, checkVoucherData } = useStoreActions(
    (action) => action.voucherModel,
  )

  const voucherTracker = (eventName, voucherCode) => {
    return trackEvent.payment(
      eventName,
      { ...trackerData, itemType },
      {
        modal_name: `PURCHASE_${itemType.toLocaleUpperCase()}`,
        voucher_code: voucherCode,
        user_type: getIdToken() ? 'supporter' : 'guest',
        event_quantity:
          itemType === 'event' &&
          selectedProducts?.products[0]?.tickets[0]?.qty,
        is_member_community: community?.is_member || '',
      },
    )
  }

  useEffect(() => {
    if (voucherDetails) {
      setSelectedVoucher(voucherDetails)
    }
  }, [voucherDetails])

  useEffect(() => {
    if (voucherData !== null) {
      setAlertVoucher(true)
      voucherDetailClose()
    } else if (isError && isCardCheck) {
      voucherDetailClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherData, isError, errorMessage, isCardCheck])

  function voucherChecker(voucherName, isCardCheck) {
    voucherTracker('click_voucher_code_fill_in', voucherName)
    setVoucherData(null)
    if (page && selectedProducts) {
      setIsCardCheck(isCardCheck)
      checkVoucherData({
        products: selectedProducts?.products,
        user_mask_id: '',
        voucher_code: voucherName,
        payment_method: selectedProducts?.payment_method,
        idempotentKey: idempotentKey,
        checkout_source: selectedProducts?.checkout_source,
      })
    }
  }

  return (
    <Box>
      {voucherData !== null && (
        <Collapse in={alertVoucher}>
          <Box
            sx={{
              marginTop: 10,
              marginBottom: '-54px',
              width: '100%',
            }}
          >
            <Alert
              variant="outlined"
              severity="success"
              sx={{
                color: theme.palette.text.primary,
                width: '100%',
                padding: 1,
              }}
              icon={<CheckCircleIcon />}
            >
              Selamat! Kamu berhasil menggunakan voucher{' '}
              {voucherData?.payment_method[1]?.voucher_code}!
            </Alert>
          </Box>
        </Collapse>
      )}
      {selectedVoucher !== null ? (
        <Box>
          <VoucherCard
            voucherListData={selectedVoucher}
            voucherChecker={voucherChecker}
            isLoading={isLoading}
          />
          <MainTypography>{selectedVoucher?.name}</MainTypography>
          <SubTypography>
            <li>
              Potongan sampai dengan{' '}
              {numberFormatter(selectedVoucher?.max_discount_amount)}
            </li>
          </SubTypography>
          <StyledDivider />
          <MainTypography>
            {htmlParse(selectedVoucher?.description)}
          </MainTypography>
        </Box>
      ) : (
        <PageNotFound />
      )}
    </Box>
  )
}
