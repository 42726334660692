import mixpanel from 'mixpanel-browser'
import mixPanelConfig from 'configs/mixpanel'
import stateMapping from 'constants/state-mapping'
import isEmpty from 'helpers/is-empty'
import get from 'lodash/get'
import { getCookie } from 'helpers/cookie'
import { getQueryParam } from 'helpers/url'
import { ISessionDetailData } from 'stores/domains/Session/SessionDetail/interface'
import { setSessionStorage } from 'helpers/session-storage'
import whitelistedEvents from 'constants/mixpanel/tracker-whitelist'

function initAnalytics(): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true'
  ) {
    mixpanel.init(mixPanelConfig.token, {
      ignore_dnt: true,
      debug: mixPanelConfig.debug === 'true',
      persistence: 'localStorage',
      loaded: function (mixpanel) {
        // eslint-disable-next-line no-console
        console.log('Mixpanel successfully loaded')
        const distinctId = mixpanel.get_distinct_id()
        // eslint-disable-next-line no-console
        console.log('distinct id : ', distinctId)
        setSessionStorage('fp', distinctId)

        // do identify for logged in user
        const userId = getCookie('user_id')
        if (!isEmpty(userId)) {
          mixpanel.identify(userId)
        }
        campaignParams()
      },
    })
  }
}

function trackEvent(
  eventName: string,
  eventProperties?: Object,
): void {
  //whitelisted events
  if (whitelistedEvents.includes(eventName))
    if (
      mixPanelConfig.enabled === 'true' &&
      process.env.NEXT_IS_SERVER !== 'true' &&
      mixpanel.config.token
    ) {
      mixpanel.track(eventName, eventProperties, {
        send_immediately: true,
      })
    }
}

function setDefaultProperties(properties?: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.register(properties)
  }
}

function setUserRegister(userId: string, properties?: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.alias(userId, mixpanel.get_distinct_id())
    mixpanel.identify(userId)
    mixpanel.people.set(properties)
  }
}

function setUserLogin(userId: string, properties?: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.identify(userId)
    mixpanel.people.set(properties)
  }
}

function resetUser(): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.reset()
  }
}

function setPeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.set(properties)
  }
}

function setOncePeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.set_once(properties)
  }
}

function appendListToPeopleProperties(list: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.append(list)
  }
}

function unionListToPeopleProperties(list: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.union(list)
  }
}

function removeListFromPeopleProperties(list: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.remove(list)
  }
}

function incrementPeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.increment(properties)
  }
}

function getStateFromSource(source: string): string {
  const index = stateMapping.findIndex(
    (state) => state?.source === source,
  )
  return isEmpty(stateMapping[index]?.state)
    ? ''
    : stateMapping[index].state
}

function campaignParams() {
  var campaign_keywords =
      'utm_source utm_medium utm_campaign utm_content utm_term'.split(
        ' ',
      ),
    keyword = '',
    params = {},
    first_params = {}
  var index
  for (index = 0; index < campaign_keywords.length; ++index) {
    keyword = getQueryParam(document.URL, campaign_keywords[index])
    if (keyword.length) {
      params[campaign_keywords[index] + ' [last touch]'] = keyword
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    keyword = getQueryParam(document.URL, campaign_keywords[index])
    if (keyword.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] =
        keyword
    }
  }
  mixpanel.people.set(params)
  mixpanel.people.set_once(first_params)
  mixpanel.register(params)
}

function getDistinctID(): string {
  try {
    if (
      mixPanelConfig.enabled === 'true' &&
      process.env.NEXT_IS_SERVER !== 'true'
    ) {
      return mixpanel.get_distinct_id()
    } else {
      return 'fake-distinct-id-123'
    }
  } catch (error) {
    return ''
  }
}

function trackMixPanelSession(
  eventName: string,
  sessionDetail: ISessionDetailData,
  additionalProperties = {},
) {
  trackEvent(eventName, {
    ...additionalProperties,
    session_status: get(
      sessionDetail,
      'sessionData.session_status',
      '',
    ).toLowerCase(),
    session_type: get(sessionDetail, 'sessionData.session_type'),
    session_title: get(sessionDetail, 'sessionData.title'),
    session_price_per_participant: get(
      sessionDetail,
      'sessionData.price',
      0,
    ),
    max_num_participant: get(sessionDetail, 'sessionData.slots', 0),
    session_description: get(
      sessionDetail,
      'sessionData.description',
    ),
    estimate_session_start_datetime: get(
      sessionDetail,
      'sessionData.start_time',
    ),
    estimate_session_duration:
      get(sessionDetail, 'sessionData.duration', 0) * 60, // seconds
    session_photo_image_path: get(sessionDetail, 'sessionData.cover'),
    creator_id: get(
      sessionDetail,
      'sessionData.creator.creator_id',
      '',
    ),
    creator_name: get(sessionDetail, 'sessionData.creator.name', ''),
    creator_user_id: get(
      sessionDetail,
      'sessionData.creator.creator_user_id',
      '',
    ),
    creator_category: get(
      sessionDetail,
      'sessionData.creator.category.name',
      '',
    ),
    creator_category_id: get(
      sessionDetail,
      'sessionData.creator.category.id',
      '',
    ),
  })
}

export {
  initAnalytics,
  trackEvent,
  setDefaultProperties,
  setUserRegister,
  setUserLogin,
  resetUser,
  setPeopleProperties,
  setOncePeopleProperties,
  appendListToPeopleProperties,
  unionListToPeopleProperties,
  removeListFromPeopleProperties,
  incrementPeopleProperties,
  getStateFromSource,
  getDistinctID,
  trackMixPanelSession,
}
