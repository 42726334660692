import { debounce, styled } from '@mui/material'
import { useRouter } from 'next/router'

import { useStoreActions, useStoreState } from 'stores/hooks'
import trackEvent from 'src/trackers'
import { sendLog } from 'helpers/log'

import SnackbarNotification from 'components/commons/SnackbarNotification'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import {
  StyledStack,
  ReferralCodeContainer,
  ReferralCodeLabel,
  ReferralCodeValue,
  StyledIconContainer,
} from 'containers/domains/Wallet/ReferralCode/styles'
import { useProfileUserStore } from 'stores/domains/User'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShareIcon from '@mui/icons-material/Share'
import { useState } from 'react'

const StyledNumberTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: '16px',
}))

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
})

function ReferralCodeListDrawer() {
  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }
  const [copyReferralCode, setCopyReferralCode] = useState(false)

  const { profileData } = useProfileUserStore((state) => ({
    profileData: state.profileData,
  }))

  const { showReferralCodeListDrawer } = useStoreState(
    (state) => state.referralCode,
  )

  const {
    setShowReferralCodeListDrawer,
    setSelectedShareReferralCode,
    setShowShareReferralCodeDrawer,
    generateReferralLink,
  } = useStoreActions((actions) => actions.referralCode)

  const handleCloseReferralCodeDrawer = () => {
    trackEvent.user('creator_close_referral_code_list', trackerData, {
      list_referral_code: profileData?.referral_codes,
      modal_name: 'REFERRAL_CODE_LIST',
    })
    setShowReferralCodeListDrawer(false)
  }

  const handleCopyReferralCode = async (code) => {
    trackEvent.user('creator_click_copy_referral_code', trackerData, {
      referral_code: code,
      list_referral_code: profileData?.referral_codes,
    })
    try {
      await navigator.clipboard.writeText(code)
      setCopyReferralCode(true)
      setTimeout(() => {
        setCopyReferralCode(false)
      }, 2000)
    } catch (error) {
      sendLog(error)
      setCopyReferralCode(false)
    }
  }

  const handleShareReferralCode = async (code) => {
    trackEvent.user('click_share_referral_code', trackerData, {
      referral_code: code,
      list_referral_code: profileData?.referral_codes,
      modal_name: 'REFERRAL_CODE_LIST',
    })
    setSelectedShareReferralCode(code)
    try {
      const response = await generateReferralLink(code)
      if (response?.data?.code === 'SUCCESS') {
        setShowShareReferralCodeDrawer(true)
      }
    } catch (error) {
      sendLog(error)
    }
  }

  return (
    <ModalDrawer
      title="Semua Kode Referral"
      isOpen={showReferralCodeListDrawer}
      onClose={debounce(handleCloseReferralCodeDrawer, 250)}
      closeButtonId="b-users-close-referral-code-list-drawer"
      submitButtonId="b-users-submit-referral-code-list"
      sxDialogContainer={{
        padding: '16px 16px 24px',
        '& .MuiDialogContent-root': { padding: 0, marginTop: '8px' },
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
      sxDrawerContainer={{ paddingY: '16px' }}
    >
      <Stack
        id="c-users-referral-code-drawer-stack-container"
        spacing={1.5}
      >
        {profileData?.referral_codes?.map((item, index) => (
          <StyledStack
            key={`referral-code-${index}`}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.tertiery,
              padding: '12px',
              margin: 0,
              borderRadius: '8px',

              '&:hover': {
                opacity: '75%',
              },
            })}
          >
            <StyledContainer>
              <StyledNumberTypography>
                {index + 1}
              </StyledNumberTypography>
              <ReferralCodeContainer
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                style={{
                  marginLeft: 12,
                  width: 'auto',
                  minWidth: '100%',
                }}
              >
                <ReferralCodeLabel>Kode Referral</ReferralCodeLabel>
                <ReferralCodeValue
                  style={{ fontSize: 14 }}
                  id="c-wallet-referral-code-label"
                >
                  {item}
                </ReferralCodeValue>
              </ReferralCodeContainer>
            </StyledContainer>
            <Stack direction="row" alignItems="center">
              <StyledIconContainer
                id="b-wallet-copy-referral-code-button"
                onClick={() => handleCopyReferralCode(item)}
              >
                <ContentCopyIcon />
              </StyledIconContainer>
              <StyledIconContainer
                id="b-wallet-share-referral-code-button"
                sx={{ marginLeft: '16px !important' }}
                onClick={() => handleShareReferralCode(item)}
              >
                <ShareIcon />
              </StyledIconContainer>
            </Stack>
          </StyledStack>
        ))}
      </Stack>
      <SnackbarNotification
        open={copyReferralCode}
        autoHideDuration={2000}
        severity={'success'}
        sx={{
          bottom: '90px',
          left: '15px',
          right: '15px',
        }}
        desc="Kode referral berhasil disalin"
      />
    </ModalDrawer>
  )
}

export default ReferralCodeListDrawer
