import create from 'zustand'
import { IBrowseEvent } from './Event/interface'
import { createBrowseEventStore } from './Event'
import { IBrowseRecommendationSession } from './RecommendationSession/interface'
import { createBrowseRecommendationSessionStore } from './RecommendationSession'
import IBrowse from './Browse/interface'
import { createBrowseStore } from './Browse'

export const useBrowseEventStore = create<IBrowseEvent>()((...a) => ({
  ...createBrowseEventStore(...a),
}))

export const useBrowseRecommendationSessionStore =
  create<IBrowseRecommendationSession>()((...a) => ({
    ...createBrowseRecommendationSessionStore(...a),
  }))

export const useBrowseStore = create<IBrowse>()((...a) => ({
  ...createBrowseStore(...a),
}))
