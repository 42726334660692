import { DeltaOperation } from 'quill'
import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'
import { ImageListType } from 'react-images-uploading'

export const getListCommunities = async (params = {}) => {
  return await apiClient({
    url: '/social/v1/community/list',
    method: 'GET',
    token: getIdToken(),
    params,
  }).catch((error) => {
    return Promise.reject(error)
  })
}
export interface ICommunityDetailPayload {
  name: string
  category_id: number
  is_private?: boolean
}

export interface ICommunityQuestionPayload {
  num_members_code: number
  member_ages_code: number
  member_gender_code: number
  member_province_codes: number[]
}

export interface IEditCommunityPayload
  extends ICommunityDetailPayload {
  thumbnail_image?: string
  banner_image?: string
  link_fb?: string
  link_twitter?: string
  link_instagram?: string
  link_youtube?: string
  link_linkedin?: string
  link_tiktok?: string
  link_whatsapp?: string
  link_telegram?: string
}

export type ICreateCommunityPayload = ICommunityDetailPayload &
  ICommunityQuestionPayload

export const createCommunity = async (
  data: Partial<ICreateCommunityPayload>,
) => {
  return await apiClient({
    url: '/social/v1/community',
    method: 'POST',
    token: getIdToken(),
    data,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

interface IDataFollow {
  username: string
  following: boolean
}

export const getFollowers = async (username: string) => {
  const url = !isGuestMode()
    ? `/social/v1/follows/${username}`
    : `/social/guest/v1/follows/${username}`

  const response = await apiClient({
    url,
    method: 'GET',
    token: getIdToken(),
  })
  return response?.data
}

export const postFollow = async (
  token: string,
  data: IDataFollow,
) => {
  if (!token) return {}
  const response = await apiClient({
    url: `/social/v1/follows/${data.username}`,
    method: 'POST',
    data: {
      creator_username: data.username,
      set_following: data.following,
    },
    token: token,
  })
  return response?.data
}

export const getCommunityDetail = async (
  id: string,
  withoutHeaders: boolean = false,
) => {
  let url = `/social/v2/community/${id}/detail`
  if (withoutHeaders || isGuestMode()) {
    url = `/social/guest/v2/community/${id}/detail`
  }
  return await apiClient({
    url,
    method: 'GET',
    token: withoutHeaders || isGuestMode() ? undefined : getIdToken(),
    withoutHeaders,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

interface ICommunityMemberParams {
  page: number
  limit: number
}

export const getCommunityMember = async (
  id: string,
  params: ICommunityMemberParams,
) => {
  let url = `/social/guest/v1/community/${id}/member`
  if (!isGuestMode()) url = `/social/v1/community/${id}/member`
  return await apiClient({
    url,
    method: 'GET',
    token: isGuestMode() ? undefined : getIdToken(),
    params,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getCommunityInfo = async () => {
  return await apiClient({
    url: '/social/v1/community/info',
    method: 'GET',
    token: getIdToken(),
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const updateCommunityDetail = async (
  id: string,
  data: IEditCommunityPayload,
) => {
  return await apiClient({
    url: `/social/v1/community/${id}`,
    method: 'PUT',
    token: getIdToken(),
    data,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const putCommunityDescription = async (
  id: string,
  payload: {
    delta_description: DeltaOperation[]
  },
) => {
  return await apiClient({
    url: `/social/v1/community/${id}/desc`,
    method: 'PUT',
    token: getIdToken(),
    data: {
      ...payload,
    },
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const postCommunityDescriptionGenAI = async (
  id: string,
  payload: {
    target_audience: string
    community_name: string
    community_category: string
    community_activity: string
    accept_sponsorship: boolean
  },
) => {
  return await apiClient({
    url: `/social/v1/community/${id}/desc/generate`,
    method: 'POST',
    token: getIdToken(),
    data: {
      ...payload,
    },
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const communityPresignURL = async (
  data: ImageListType,
  target:
    | 'community_thumbnail'
    | 'community_banner'
    | 'community_group_chat_member'
    | 'community_activity',
) => {
  const filename = data[0]?.file?.name
  return await apiClient({
    url: `/social/v1/community/presign-url`,
    method: 'POST',
    token: getIdToken(),
    data: {
      target,
      ext:
        '.' +
        filename
          .substring(filename.lastIndexOf('.') + 1)
          .toLowerCase(),
    },
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const postJoinLeaveCommunity = async (
  id: string,
  join: boolean,
  additionalInfo: any,
) => {
  return await apiClient({
    url: `/social/v1/community/${id}/member`,
    method: 'POST',
    token: getIdToken(),
    data: {
      join,
      additional_info: additionalInfo,
    },
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const generateShareCommunity = async (id: string) => {
  return await apiClient({
    url: isGuestMode()
      ? `/social/guest/v1/community/${id}/link`
      : `/social/v1/community/${id}/link`,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getListScreeningQuestion = async (id: string) => {
  return await apiClient({
    url: `/social/v1/community/${id}/form`,
    method: 'GET',
    token: getIdToken(),
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const submitCommunityVerification = async (
  communityId: string,
  payload,
) => {
  return await apiClient({
    url: `/social/v1/community/${communityId}/verification`,
    method: 'PUT',
    token: getIdToken(),
    data: payload,
  })
}

type AudienceType = 'PERSONAL' | 'CREATOR' | 'BUSINESS' | 'COMMUNITY'

export const getMissionCategories = async (
  audience: AudienceType = 'COMMUNITY',
) => {
  return await apiClient({
    url: '/social/v1/mission/category/progress',
    method: 'GET',
    token: getIdToken(),
    params: { audience },
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getMissionDetail = async (id: string) => {
  return await apiClient({
    url: `/social/v1/mission/category/${id}/tracker`,
    method: 'GET',
    token: getIdToken(),
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getCommunityEventSchedule = async (
  id: string,
  payload?: object,
) => {
  const additionalPath = isGuestMode() ? '/guest' : ''
  const url = `/content${additionalPath}/v2/community/${id}/event/schedule`
  return await apiClient({
    url,
    method: 'GET',
    token: isGuestMode() ? undefined : getIdToken(),
    withoutHeaders: isGuestMode(),
    params: payload,
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getCommunityProductList = async (
  id: string,
  payload?: object,
) => {
  const additionalPath = isGuestMode() ? '/guest' : ''
  const url = `/content${additionalPath}/v1/community/${id}/product/list`
  return await apiClient({
    url,
    method: 'GET',
    token: isGuestMode() ? undefined : getIdToken(),
    withoutHeaders: isGuestMode(),
    params: payload,
  }).catch((error) => {
    return Promise.reject(error)
  })
}
