import Box from 'components/commons/Box'
import ActionButton from 'components/commons/Button/Action'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import appConfig from 'configs/app'
import htmlParse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

export const PaymentInfoCoinModal = ({
  paymentItem,
  openCoinInfo,
  setOpenCoinInfo,
}) => {
  const { t } = useTranslation()
  return (
    <ModalDrawer
      title={t('key_tiptip_coin')}
      isOpen={openCoinInfo}
      onClose={() => {
        setOpenCoinInfo(false)
      }}
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
      sxDrawerContainer={{ padding: '16px 0px' }}
    >
      <Box style={{ fontSize: '12px', marginBottom: '16px' }}>
        {htmlParse(String(paymentItem?.info))}
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          fontSize: '12px',
        }}
      >
        <ActionButton
          buttonVariant="outlined"
          sx={{
            padding: '12px 24px',
            width: '100%',
            marginRight: '8px',
          }}
          onClick={() => {
            window.open(appConfig.coinGuidelineUrl, '_blank')
          }}
        >
          {t('paymentMethodV2WidgetShowCaseCoinBtn')}
        </ActionButton>

        <ActionButton
          sx={{
            padding: '12px 24px',
            width: '100%',
            marginLeft: '8px',
          }}
          onClick={() => {
            setOpenCoinInfo(false)
          }}
        >
          {t('back')}
        </ActionButton>
      </Box>
    </ModalDrawer>
  )
}
