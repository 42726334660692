import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  debounce,
  FormControl,
  styled,
  TextField,
  useTheme,
} from '@mui/material'

import Grid from 'components/commons/Grid'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import { useRouter } from 'next/router'
import isEmpty from 'helpers/is-empty'
import trackEvent from 'src/trackers'
import { setPeopleProperties } from 'helpers/analytics'
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import { useStoreActions, useStoreState } from 'stores/hooks'

import MessageBox from 'components/commons/MessageBox'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import TextError from 'components/domains/User/TextError'

import DeleteIcon from '@mui/icons-material/Delete'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  useProfileUserStore,
  useReferralStore,
} from 'stores/domains/User'
import { useProfileUser } from 'hooks/domains/User'
import { getReferralCodeInfo } from 'services/user'
import Button from 'components/commons/Button'
import { Form } from 'components/commons/Form'
import LoadingButton from 'components/commons/LoadingButton'

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.normalRegular,
}))

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.normalBold,
}))

type FormData = {
  referralCode: string
}

const StyledDeleteContainer = styled(Button)(({ theme }) => ({
  background: 'transparent',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.tiptip[500]}`,
  marginTop: '16px',
  width: '44px',
  height: '44px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    color: theme.palette.tiptip[500],
  },
  minWidth: '44px',
  ':disabled': {
    svg: {
      color: theme.palette.text.tertiery,
    },
    border: `1px solid ${theme.palette.background.tertiery}`,
  },
}))

const StyledClearIcon = styled(Box)(({ theme }) => ({
  background: theme.palette.background.tertiery,
  width: '16px',
  height: '16px',
}))

function ReferralCodeDrawer({ t }) {
  const theme = useTheme()
  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }
  const [isRequesting, setIsRequesting] = useState(false)
  const [messageBox, setMessageBox] = useState('')
  const [isErrorSubmit, setIsErrorSubmit] = useState(false)

  const { showReferralCodeDrawer } = useStoreState(
    (state) => state.referralCode,
  )

  const { setShowReferralCodeDrawer } = useStoreActions(
    (actions) => actions.referralCode,
  )

  const { setUserProfile } = useProfileUserStore((state) => ({
    setUserProfile: state.setUserProfile,
  }))
  const { setShowReferralInfoDrawer } = useReferralStore((state) => ({
    setShowReferralInfoDrawer: state.setShowReferralInfoDrawer,
  }))
  const {
    referralCodeInfo,
    clearReferralCodeInfo,
    setReferralCodeInfo,
  } = useReferralStore((state) => ({
    referralCodeInfo: state.referralCodeInfo,
    clearReferralCodeInfo: state.clearReferralCodeInfo,
    setReferralCodeInfo: state.setReferralCodeInfo,
  }))

  const { refetch: refetchProfile, data: userData } = useProfileUser()

  const {
    control,
    formState: { errors, isValid },
    setValue,
    resetField,
    getValues,
    setError,
  } = useForm<FormData>({
    mode: 'onChange',
  })

  const handleCloseReferralCodeDrawer = () => {
    resetField('referralCode')
    trackEvent.user('cancel_referral_code_fill_in', trackerData)
    if (isEmpty(referralCodeInfo.user)) {
      setShowReferralInfoDrawer(false)
    }
    setShowReferralCodeDrawer(false)
  }

  const checkingReferralCodeinfo = async () => {
    const referral_code = getValues('referralCode')
    setIsRequesting(true)
    try {
      const response = await getReferralCodeInfo(referral_code)
      if (response?.data?.code === 'SUCCESS') {
        trackEvent.user('submit_referral_code', trackerData, {
          referral_code: referral_code,
          use_referral_code_link: !isEmpty(
            getLocalStorage('referralCode'),
          ),
          referrer_code: referralCodeInfo.user?.referral_code,
        })
        setPeopleProperties({
          referrer_code: referralCodeInfo.user?.referral_code,
          use_referral_code_link: !isEmpty(
            getLocalStorage('referralCode'),
          ),
        })
        setLocalStorage('referralCode', { code: referral_code })
        setReferralCodeInfo(response.data.data)
        resetField('referralCode')
        setShowReferralCodeDrawer(false)
        setShowReferralInfoDrawer(true)
        setIsRequesting(false)
        if (!pathname?.includes('post-registration')) {
          refetchProfile()
        }
      }
    } catch (error) {
      setIsRequesting(false)
      if (error.response.data.code === 'NOT_FOUND') {
        setError('referralCode', {
          type: 'custom',
          message: 'Kode referral invalid',
        })
      } else {
        setIsErrorSubmit(true)
        setMessageBox(
          error?.response?.data?.message
            ? error.response.data.message
            : 'Ups, terjadi kesalahan, coba beberapa saat lagi',
        )
      }
    }
  }

  const renderErrorMessage = () => {
    let errorMessage = ''
    if (errors?.referralCode?.type === 'required') {
      errorMessage = 'Kode referral tidak boleh kosong.'
    } else if (errors?.referralCode?.type === 'maxLength') {
      errorMessage =
        'Kode referral terlalu panjang, maksimum 20 karakter.'
    } else if (errors?.referralCode?.type === 'pattern') {
      errorMessage = 'Masukan kode referral yang valid.'
    } else if (errors?.referralCode?.type === 'custom') {
      errorMessage = errors?.referralCode?.message
    }
    return <TextError text={errorMessage} />
  }

  useEffect(() => {
    if (referralCodeInfo.user && showReferralCodeDrawer) {
      setValue('referralCode', referralCodeInfo.user.referral_code, {
        shouldValidate: true,
      })
    }
  }, [referralCodeInfo, showReferralCodeDrawer])

  useEffect(() => {
    if (userData) {
      setUserProfile(userData)
    }
  }, [userData])

  return (
    <ModalDrawer
      isOpen={showReferralCodeDrawer}
      onClose={debounce(handleCloseReferralCodeDrawer, 250)}
      title={t('referralCode')}
      closeButtonId="b-users-close-referral-code-drawer"
      submitButtonId="b-users-submit-referral-code"
      sxDialogContainer={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
      }}
    >
      <Grid
        id="c-users-referral-code-drawer-grid-container"
        container
        spacing={2}
        sx={{
          paddingBottom: {
            xs: '24px',
            md: 0,
          },
        }}
      >
        <Grid id="c-users-referral-code-drawer-grid-item" item>
          <SubTitle id="c-users-sub-title">
            {t('inputYourReferralCode')}
          </SubTitle>
        </Grid>
        <Grid
          id="c-users-referral-code-drawer-grid-item2"
          item
          xs={12}
        >
          <Form id="f-users-referral-code-drawer" autoComplete="off">
            <FormControl variant="standard" fullWidth>
              <Label id="c-users-input-referral-code-label">
                {t('referralCode')}
              </Label>
              <Controller
                name="referralCode"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  maxLength: 20,
                  minLength: 8,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="i-users-referral-code"
                    fullWidth
                    placeholder={t('inputYourReferralCode')}
                    inputProps={{
                      style: {
                        marginTop: '4px',
                        background: theme.palette.background.tertiery,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {!isEmpty(getValues('referralCode')) && (
                            <StyledClearIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                resetField('referralCode')
                                setValue('referralCode', '')
                              }}
                            >
                              <CancelIcon
                                color="tertiery"
                                sx={{ fontSize: '16px' }}
                              />
                            </StyledClearIcon>
                          )}
                        </>
                      ),
                    }}
                    sx={{
                      '.MuiFormHelperText-root.Mui-error': {
                        color: theme.palette.error.main,
                        marginLeft: 0,
                      },
                      'input::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                      },
                      '& .MuiOutlinedInput-root': {
                        background: theme.palette.background.tertiery,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        ':hover': {
                          border: 'none',
                        },
                      },
                      input: {
                        background: theme.palette.background.tertiery,
                        ...theme.typography.normalRegular,
                        borderRadius: '8px',
                        ':-internal-autofill-selected': {
                          backgroundColor:
                            theme.palette.background.tertiery,
                        },
                      },
                    }}
                    error={errors?.referralCode ? true : false}
                    onChange={(e) =>
                      setValue('referralCode', e.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              />
              {errors?.referralCode && (
                <Grid
                  id="c-users-handphone-error-grid-item"
                  item
                  xs={12}
                >
                  {renderErrorMessage()}
                </Grid>
              )}
              {!isEmpty(messageBox) && (
                <MessageBox
                  open={!isEmpty(messageBox)}
                  onClose={() => {
                    setIsErrorSubmit(false)
                    setMessageBox('')
                  }}
                  isError={isErrorSubmit}
                  message={messageBox}
                />
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <LoadingButton
                  sx={{
                    width: '100%',
                    marginRight: '8px',
                    height: '44px',
                    marginTop: '16px',
                  }}
                  buttonVariant="filled"
                  type="submit"
                  disabled={
                    !isValid ||
                    isRequesting ||
                    getValues('referralCode') ===
                      referralCodeInfo.user?.referral_code ||
                    isErrorSubmit
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    checkingReferralCodeinfo()
                  }}
                >
                  {t('key_check_referral_code')}
                </LoadingButton>
                <StyledDeleteContainer
                  disabled={
                    !isValid ||
                    isRequesting ||
                    referralCodeInfo.user === null
                  }
                  onClick={() => {
                    clearReferralCodeInfo()
                    resetField('referralCode')
                    setValue('referralCode', '')
                  }}
                >
                  <DeleteIcon />
                </StyledDeleteContainer>
              </Stack>
            </FormControl>
          </Form>
        </Grid>
      </Grid>
    </ModalDrawer>
  )
}

export default ReferralCodeDrawer
